import {
	InfiniteData,
	UseInfiniteQueryResult,
	useInfiniteQuery,
} from "@tanstack/react-query";
import { anyAnimeClient } from "../services/anyanime.service";
import {
	GetTypeInput,
	GetTypeOutput,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";

export type UseInfiniteGetTypeResults = UseInfiniteQueryResult<
	InfiniteData<GetTypeOutput, unknown>,
	Error
>;

export const useGetType = ({
	query,
	nextToken,
}: GetTypeInput): UseInfiniteGetTypeResults => {
	const results = useInfiniteQuery({
		queryKey: [QUERY_KEYS.useGetType, query, nextToken],
		queryFn: async ({ pageParam: nextToken }) => {
			return await anyAnimeClient.getType({
				query,
				nextToken,
			});
		},
		gcTime: 60 * 60 * 1000,
		getNextPageParam: (lastPage) => lastPage.nextToken,
		initialPageParam: "",
	});
	return results;
};
