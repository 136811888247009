import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
	DescribeEpisodesInput,
	DescribeEpisodesOutput,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";
import { anyAnimeClient } from "../services/anyanime.service";

export type UseDescribeEpisodesResults = UseQueryResult<
	DescribeEpisodesOutput,
	unknown
>;

export const useDescribeEpisodes = ({
	query,
}: DescribeEpisodesInput): UseDescribeEpisodesResults => {
	return useQuery({
		queryKey: [QUERY_KEYS.useDescribeEpisodes, query],
		queryFn: async () => {
			return await anyAnimeClient.describeEpisodes({
				query,
			});
		},
		gcTime: 60 * 60 * 1000,
	});
};
