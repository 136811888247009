import { Alert } from "@mui/material";
import React, { Component } from "react";

interface ErrorBoundaryProps {
	children: React.ReactNode | React.ReactNode[];
}

interface ErrorBoundaryState {
	error: boolean;
}

export class ErrorBoundary extends Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	state = { error: false };

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { error: true };
	}
	componentDidCatch() {
		this.setState({ error: true });
	}

	render() {
		if (this.state.error) {
			// Render an error message or fallback UI
			return (
				<Alert variant="filled" severity="error">
					Something Went Wrong!!
				</Alert>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
