import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
	WatchLinksInput,
	WatchLinksOutput,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";
import { anyAnimeClient } from "../services/anyanime.service";

export type UseWatchLinksResults = UseQueryResult<WatchLinksOutput, unknown>;

export const useWatchLinks = (query: WatchLinksInput): UseWatchLinksResults => {
	return useQuery({
		queryKey: [QUERY_KEYS.useWatchLinks, query],
		queryFn: async () => {
			return await anyAnimeClient.watchLinks(query);
		},
		gcTime: 60 * 60 * 1000,
	});
};
