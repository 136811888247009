import { Alert, Box } from "@mui/material";
import { memo, useContext } from "react";
import { SuggestionSwiperContext } from "../../Context/SuggestionSwiper.context";
import { ImageAndTitleCardSkeleton } from "../LoadingSpinner/ImageAndTitleCardSkeleton";
import { SuggestionSwiper } from "./SuggestionSwiper";

export const genreToHeadingMap: Record<string, string[]> = {
	Update: [
		"Hot off the press!",
		"Freshly baked content!",
		"Catch up or miss out!",
	],
	Action: ["Boom! Pow! Zing!", "Action overload!", "Get ready to rumble!"],
	"Adult Cast": ["All grown up!", "Not for the kiddos!", "Mature and sassy!"],
	Anthropomorphic: [
		"Animals with attitude!",
		"Furry fun times!",
		"Not your average pets!",
	],
	AvantGarde: [
		"Artistic madness!",
		"Avant-garde craziness!",
		"Prepare for the bizarre!",
	],
	BoysLove: [
		"Boys kissing boys!",
		"Heart-throbbing bromance!",
		"Love is in the air!",
	],
	Cars: ["Need for speed!", "Vroom vroom!", "Car lovers unite!"],
	CGDCT: [
		"Cutie overload!",
		"Girls being adorable!",
		"Cuteness personified!",
	],
	Childcare: [
		"Tiny tots and big hearts!",
		"Baby love!",
		"Caring for the little ones!",
	],
	Comedy: ["Laugh till you drop!", "Comedy chaos!", "Jokes on jokes!"],
	Comic: [
		"Panel popping fun!",
		"Comic book madness!",
		"Illustrated giggles!",
	],
	Crime: ["Whodunnit?", "Criminally good!", "Detective hat on!"],
	Crossdressing: ["Dress up time!", "Fashion flips!", "Cross-dressing fun!"],
	Cultivation: [
		"Grow your power!",
		"Spiritual bootcamp!",
		"From zero to hero!",
	],
	Delinquents: [
		"Rebel rousers!",
		"Trouble with a capital T!",
		"Bad boys and girls!",
	],
	Dementia: [
		"Mind-bending craziness!",
		"Lost in the mind!",
		"Reality is overrated!",
	],
	Demons: ["Hellish fun!", "Demon days!", "Spooky and sinister!"],
	Detective: ["Sherlock who?", "Case closed!", "Mystery-solving mode!"],
	Drama: ["Tears and cheers!", "Drama central!", "Emotions on high!"],
	Dub: ["Voice-over bonanza!", "Dubbing delight!", "Get Duolingo!"],
	Ecchi: ["Cheeky and saucy!", "Playful naughtiness!", "Mildly spicy!"],
	Erotica: [
		"Steamy and intense!",
		"Adult themes galore!",
		"Get ready for heat!",
	],
	Family: ["Warm and fuzzy!", "Nostalgic feels!", "Heartwarming moments!"],
	Fantasy: ["Magic and wonder!", "Fantasy land awaits!", "Epic adventures!"],
	GagHumor: ["Gag-tastic!", "Laugh riot!", "Jokes that never end!"],
	Game: ["Level up!", "Gaming galore!", "Pixelated fun!"],
	GenderBender: [
		"Role swap mania!",
		"Gender-bending fun!",
		"Not what you expect!",
	],
	Gore: ["Bloody and gory!", "Gruesome thrills!", "Shock and awe!"],
	Gourmet: ["Feast your eyes!", "Foodie fantasy!", "Deliciously good!"],
	Harem: [
		"Love triangle chaos!",
		"Multiple love interests!",
		"Romantic overload!",
	],
	Hentai: ["Explicit content!", "Naughty and nice!", "Adult entertainment!"],
	HighStakesGame: ["High tension!", "Game on the edge!", "Risk it all!"],
	Historical: [
		"Blast from the past!",
		"History comes alive!",
		"Time travel thrills!",
	],
	Horror: ["Scary and spooky!", "Sleep with the lights on!", "Horror fest!"],
	Isekai: [
		"New worlds await!",
		"Fantasy escapes!",
		"Another world adventure!",
	],
	Iyashikei: ["Healing vibes!", "Soothing and calming!", "Peaceful retreat!"],
	Josei: ["Mature romance!", "Real life love!", "Adult stories!"],
	Kids: [
		"Fun for the young!",
		"Kid-friendly antics!",
		"Playtime adventures!",
	],
	LovePolygon: [
		"Love square and more!",
		"Romantic entanglements!",
		"Heartfelt drama!",
	],
	Magic: ["Spellbinding action!", "Magical mayhem!", "Enchanting escapades!"],
	MagicalSexShift: [
		"Transformative magic!",
		"Sexual transformation fun!",
		"Magical changes!",
	],
	MahouShoujo: [
		"Magical girl power!",
		"Heroic and cute!",
		"Sparkly adventures!",
	],
	MartialArts: ["Kung fu kicks!", "Martial mastery!", "Fight scenes galore!"],
	Mecha: ["Robots rule!", "Mecha mayhem!", "Giant robot battles!"],
	Medical: ["Doctor drama!", "Medical mysteries!", "Healthcare high stakes!"],
	Military: ["Warriors and strategy!", "Military might!", "Combat action!"],
	Music: ["Musical magic!", "Tune into fun!", "Rhythmic adventures!"],
	Mystery: ["Clues and twists!", "Mystery mayhem!", "Solve the puzzle!"],
	Mythology: ["Gods and legends!", "Mythical adventures!", "Epic tales!"],
	OrganizedCrime: [
		"Gangster life!",
		"Criminal empire!",
		"Underworld dealings!",
	],
	Parody: ["Spoof-tastic!", "Comic exaggerations!", "Parody party!"],
	PerformingArts: [
		"Stage brilliance!",
		"Artistic flair!",
		"Theater thrills!",
	],
	Pets: ["Cute and cuddly!", "Pet fun!", "Animal antics!"],
	Police: ["Law and order!", "Crime fighters!", "Police action!"],
	Psychological: [
		"Mind games!",
		"Psychological twists!",
		"Mental gymnastics!",
	],
	Racing: ["Speed demons!", "Race to win!", "Fast and furious!"],
	Reincarnation: [
		"New life adventures!",
		"Past lives and more!",
		"Rebirth tales!",
	],
	Romance: [
		"Love is in the air!",
		"Heartfelt romance!",
		"Passionate moments!",
	],
	RomanticSubtext: [
		"Hinted love!",
		"Subtle romance!",
		"Love in the background!",
	],
	Samurai: ["Warrior code!", "Samurai spirit!", "Epic sword fights!"],
	School: ["Classroom fun!", "School life antics!", "Student shenanigans!"],
	SciFi: ["Future shock!", "Sci-fi thrills!", "Tech and space!"],
	Seinen: ["Mature themes!", "Adult stories!", "Complex narratives!"],
	Shoujo: ["Girl power!", "Romantic and emotional!", "Shoujo vibes!"],
	ShoujoAi: ["Girls’ love!", "Romantic tales!", "Sweet and tender!"],
	Shounen: ["Heroic boys!", "Action-packed!", "Young and fearless!"],
	Showbiz: ["Star-studded!", "Entertainment industry!", "Showbiz drama!"],
	SliceOfLife: [
		"Everyday fun!",
		"Life’s little moments!",
		"Slice of life goodness!",
	],
	Space: ["Cosmic adventures!", "Galactic escapades!", "Starry thrills!"],
	Sports: ["Game on!", "Athletic action!", "Sporting drama!"],
	StrategyGame: ["Tactical play!", "Strategic brilliance!", "Game of wits!"],
	StrongMaleLead: [
		"Heroic and strong!",
		"Alpha males!",
		"Leading with power!",
	],
	SuperPower: ["Super abilities!", "Heroic feats!", "Power-up time!"],
	Supernatural: [
		"Otherworldly thrills!",
		"Supernatural wonders!",
		"Unseen forces!",
	],
	Survival: ["Struggle to survive!", "Life or death!", "Survival instincts!"],
	Suspense: [
		"Tense and thrilling!",
		"Edge-of-your-seat!",
		"Suspenseful moments!",
	],
	System: ["Rule-based worlds!", "Structured gameplay!", "Systematic fun!"],
	TeamSports: ["Teamwork triumphs!", "Sports and strategy!", "Group games!"],
	Thriller: [
		"Heart-pounding action!",
		"Thriller thrills!",
		"Adrenaline rush!",
	],
	TimeTravel: [
		"Temporal escapades!",
		"Journey through time!",
		"Past and future!",
	],
	Vampire: ["Fangs and blood!", "Immortal thrills!", "Gothic horror!"],
	VideoGame: ["Gaming adventures!", "Pixel fun!", "Virtual quests!"],
	VisualArts: ["Artistic brilliance!", "Visual wonders!", "Art in motion!"],
	WorkLife: ["Office drama!", "Career challenges!", "Workplace antics!"],
	Workplace: ["Daily grind!", "Office shenanigans!", "Work-life drama!"],
	Yaoi: ["Boys’ love!", "Romantic and steamy!", "Heartfelt connections!"],
	Yuri: ["Girls’ love!", "Sweet and romantic!", "Tender relationships!"],
};

export const SuggestionContainer = memo(() => {
	const { data, isError, isLoading } = useContext(
		SuggestionSwiperContext
	).state;

	let content;

	if (isLoading) {
		content = <ImageAndTitleCardSkeleton />;
	}

	if (isError) {
		content = <Alert severity="error">Something went wrong!!</Alert>;
	}

	if (data) {
		const genres = Object.keys(data.data);
		content = genres.map((genre) => {
			const heading = genreToHeadingMap[genre]
				? genreToHeadingMap[genre][
						Math.floor(
							Math.random() * genreToHeadingMap[genre].length
						)
				  ]
				: genre;
			return (
				<SuggestionSwiper
					key={genre}
					headingName={heading}
					showsList={data.data[genre]}
					isUpdate={genre === "Update"}
				/>
			);
		});
	}

	return (
		<Box
			className="suggestion-container"
			sx={{
				marginTop: { xs: "10vi", sm: "1vi", md: "-5vi" },
				zIndex: 5,
				position: "relative",
				display: "grid",
				rowGap: "2.5rem",
				gridTemplateColumns: "minmax(0,auto)",
			}}
		>
			{content}
		</Box>
	);
});
