import { Alert, Box } from "@mui/material";
import { Fragment, memo, useContext } from "react";
import { useParams } from "react-router-dom";
import { WindowWidthContext } from "../../Context/WindowWidth.context";
import { useDescribeAnime } from "../../hooks/useDescribeAnime";
import { CommentsSection } from "../CommentsSection/CommentsSection";
import { EpisodesList } from "../EpisodesList/EpisodesList";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { MoreLikeThis } from "../MoreLikeThis/MoreLikeThis";
import { DetailCard } from "./DetailCard";

export const DetailsContainer = memo(() => {
	const { id } = useParams();
	const { windowWidth } = useContext(WindowWidthContext);
	const {
		data: animeDetails,
		isError,
		isLoading,
	} = useDescribeAnime({ query: id ?? "" });

	const getColumnsCount = () => {
		if (windowWidth < 500) {
			return 2;
		} else if (windowWidth >= 500 && windowWidth < 900) {
			return 3;
		} else if (windowWidth >= 900 && windowWidth < 1240) {
			return 4;
		} else if (windowWidth >= 1240 && windowWidth < 1540) {
			return 5;
		} else if (windowWidth >= 1540 && windowWidth < 1920) {
			return 6;
		} else if (windowWidth >= 1920) {
			return 7;
		} else {
			return 8;
		}
	};

	let content;
	if (isLoading) {
		content = <LoadingSpinner />;
	}
	if (isError) {
		content = <Alert severity="error">Something went wrong!!</Alert>;
	}
	if (animeDetails) {
		content = (
			<Fragment>
				<DetailCard animeDetails={animeDetails.data} />
				<EpisodesList animeName={animeDetails.data.Name} />
				<MoreLikeThis
					baseAnime={animeDetails.data.Name}
					columnCount={getColumnsCount()}
				/>
				<CommentsSection
					uniqueId={animeDetails.data.Name}
					title={animeDetails.data.Name}
				/>
			</Fragment>
		);
	}
	return (
		<Box
			className="details-page-container"
			sx={{
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				display: "flex",
				width: "100%",
			}}
		>
			{content}
		</Box>
	);
});
