import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
	GetUpdatesOutput,
	UpdateListResult,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";
import { anyAnimeClient } from "../services/anyanime.service";

export type UseGetUpdatesResults = UseQueryResult<GetUpdatesOutput, unknown>;
const getDailyIndex = (length: number): number => {
	const daysSinceEpoch = Math.floor(Date.now() / (1000 * 60 * 60 * 24));
	return daysSinceEpoch % length;
};

const getDailySlidingGenres = (
	genres: string[],
	windowSize: number = 15
): string[] => {
	const length = genres.length;

	// Ensure the window size doesn't exceed the total number of genres
	const effectiveWindowSize = Math.min(windowSize, length);

	// Calculate the index to start sliding, making sure we include the first genre
	const startIndex = Math.max(1, getDailyIndex(length - 1)); // Start from 1 to ensure the first genre is included

	// Create a circular array for the sliding window
	const circularGenres = [...genres.slice(1), ...genres.slice(1)];

	// Slice the window from the circular array and include the first genre
	const dailyGenres = [
		genres[0],
		...circularGenres.slice(
			startIndex,
			startIndex + effectiveWindowSize - 1
		),
	];

	return dailyGenres;
};
const transformData = (data: GetUpdatesOutput) => {
	const genres = Object.keys(data.data);
	return {
		data: getDailySlidingGenres(genres).reduce((acc, curr) => {
			acc[curr] = data.data[curr];
			return acc;
		}, {} as Record<string, UpdateListResult[]>),
	};
};

export const useGetUpdates = (): UseGetUpdatesResults => {
	return useQuery({
		queryKey: [QUERY_KEYS.useGetUpdates],
		queryFn: async () => {
			return await anyAnimeClient.getUpdates();
		},
		select: transformData,
		gcTime: 60 * 60 * 1000,
		enabled: false,
	});
};
