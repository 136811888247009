import { Box, Container, Link, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { Fragment, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderSearchBox } from "./HeaderSearchBox";
import { SideDrawer, SideNav } from "./SideNav";

export enum pages {
	"Movies" = "/type/Movie",
	"OVA" = "/type/OVA",
	"Specials" = "/type/Special",
	"New & Popular" = "/type/New",
	"Anime List" = "/list",
}

const TopNavLinks = memo(() => {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				flexGrow: 1,
				display: { xs: "none", lg: "flex" },
				paddingLeft: "32px",
			}}
		>
			{Object.entries(pages).map(([page, link]) => (
				<Link
					key={page}
					onClick={() => navigate(link)}
					underline="none"
					sx={{
						my: 2,
						color: "white",
						display: "block",
						marginInline: "16px",
						fontFamily: "Trebuchet MS, Helvetica, sans-serif",
					}}
				>
					{page}
				</Link>
			))}
		</Box>
	);
});

export const TopNav = memo(() => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			setIsScrolled(scrollPosition > 0);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const toggleDrawer = () => setDrawerOpen(!drawerOpen);

	return (
		<Fragment>
			<AppBar
				position="fixed"
				sx={{
					zIndex: 1400,
					transition: "top 0.5s ease, background 0.5s ease",
					background:
						isScrolled || drawerOpen
							? "rgba(20, 20, 20)"
							: "linear-gradient(180deg, rgba(0, 0, 0, .7) 10%, transparent);",
				}}
				elevation={0}
			>
				<Container
					maxWidth={false}
					sx={{
						paddingInline: { xs: "20px", sm: "30px", md: "60px" },
					}}
				>
					<Toolbar disableGutters>
						<SideNav toggleDrawer={toggleDrawer} />
						<Link
							onClick={(event) => {
								event.preventDefault();
								navigate("/");
							}}
						>
							<Box
								sx={{
									display: { xs: "none", lg: "flex" },
									mr: 1,
									width: "110px",
									height: "30px",
								}}
							>
								<img
									src="https://coverimagecdn.s3.us-west-2.amazonaws.com/logo-no-background.svg"
									alt="AnyAnime"
								/>
							</Box>
						</Link>
						<TopNavLinks />

						<Box sx={{ flexGrow: 0, display: "inline-flex" }}>
							<HeaderSearchBox />
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<SideDrawer isOpen={drawerOpen} toggleDrawer={toggleDrawer} />
		</Fragment>
	);
});
