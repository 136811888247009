import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { Box, Button } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import ChipLayer from "./ChipLayer";
import { DetailCardProps } from "./DetailCard";
import { InfoWrapperDesktop } from "./InfoWrapper";

const DetailCardDesktop = memo(({ animeDetails }: DetailCardProps) => {
	const navigate = useNavigate();

	const onWatchClick = () => {
		navigate(`/watch/${animeDetails.Name}/1`);
	};
	return (
		<Box
			className="detail-page-desktop"
			sx={{
				display: { xs: "none", md: "block" },
				position: "relative",
				minWidth: "100%",
				overflow: "hidden",
				"& .detail-page-cover-img": {
					width: "auto",
					height: "50vi",
					maxHeight: "420px",
					objectFit: "cover",
					objectPosition: "center top",
					display: "block",
					zIndex: 2,
					position: "relative",
					paddingInline: {
						xl: "6vw",
					},
					pointerEvents: "none",
				},
			}}
		>
			<Box sx={{ minHeight: "600px" }}>
				<Box
					sx={{
						position: "absolute",
						top: "0",
						left: "0",
						right: "0",
						backgroundSize: "cover",
						backgroundPosition: "center center",
						filter: "blur(25px) grayscale(2)",
						opacity: "0.5",
						transform: "scale(1.2)",
						backgroundRepeat: "repeat-y",
						backgroundImage: `url(${animeDetails.Image})`,
						height: "inherit",
						maxHeight: "900px",
						minHeight: "600px",
						pointerEvents: "none",
					}}
				/>
				<Box
					className="detail-page-desktop-header"
					sx={{
						marginTop: "150px",
						paddingInline: {
							xs: "20px",
							sm: "30px",
							md: "60px",
						},
						display: "flex",
						flexDirection: "row",
						zIndex: 2,
					}}
				>
					<img
						className="detail-page-cover-img"
						src={animeDetails.Image}
						alt="detailPageCoverImage"
					></img>
					<Box
						sx={{
							marginInline: {
								xs: "50px",
								lg: "90px",
								xl: "1vw",
								zIndex: 2,
							},
							display: "flex",
							flexDirection: "column",
							justifyContent: "left",
							alignItems: "start",
						}}
					>
						<Box
							sx={{
								lineHeight: "2.75rem",
								fontSize: "2.25rem",
								fontWeight: "600",
								textRendering: "optimizeLegibility",
							}}
						>
							{animeDetails.Name}
						</Box>
						<InfoWrapperDesktop animeDetails={animeDetails} />
						<ChipLayer animeDetails={animeDetails} />
						<Button
							onClick={onWatchClick}
							sx={{
								backgroundColor: "white",
								border: "none",
								borderRadius: "16px",
								color: "black",
								fontSize: ".875rem",
								fontWeight: 900,
								marginTop: "30px",
								paddingRight: "17px",
								zIndex: 2,
								"&:hover": {
									color: "white",
								},
							}}
							disabled={
								!animeDetails.TotalEpisodes &&
								animeDetails.TotalEpisodes === 0
							}
						>
							<PlayArrowOutlinedIcon
								fontSize="large"
								sx={{
									color: "inherit",
								}}
							/>
							Watch now
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
});

export default DetailCardDesktop;
