import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { memo, useCallback, useState } from "react";
import "./SuggestionCarouselContents.scss";

interface SuggestionCarouselContentsProps {
	carouselImgSrc: string;
	logoImgSrc: string;
	genres: string[];
	description: string;
	watchLink: string;
	showDescription: boolean;
	isMobile: boolean;
	animeName: string;
}

export const SuggestionCarouselContents = memo(
	({
		carouselImgSrc,
		logoImgSrc,
		genres,
		description,
		watchLink,
		showDescription,
		isMobile,
		animeName,
	}: SuggestionCarouselContentsProps) => {
		const navigate = useNavigate();
		const [imageLoaded, setImageLoaded] = useState(false);

		const onWatchClick = useCallback(() => {
			navigate(watchLink);
		}, [navigate, watchLink]);

		return (
			<div
				className={`suggestion-carousel-content${
					isMobile ? "-mobile" : ""
				}`}
			>
				<img
					className="suggestion-carousel-img"
					src={carouselImgSrc}
					alt={`Carousel image for ${animeName}`}
					onLoad={() => setImageLoaded(true)}
					style={{
						filter: imageLoaded ? "none" : "blur(100px)",
						transition: "filter 0.5s ease-in-out",
					}}
				></img>
				<Box
					className="suggestion-carousel-contents-overlay"
					sx={{
						paddingInline: { xs: "20px", sm: "30px", md: "60px" },
						bottom: {
							xs: "min(calc(0.32 * 55vi), 60rem)",
							xl: "min(calc(0.4 * 55vi), 60rem)",
						},
					}}
				>
					<img
						className="suggestion-carousel-contents-logo-img"
						src={logoImgSrc}
						alt={`Logo for ${animeName}`}
						onClick={() =>
							navigate(`/anime/${encodeURIComponent(animeName)}`)
						}
					></img>
					<Box
						className="suggestions-carousel-genre-array"
						sx={{
							color: "floralwhite",
							fontFamily: "Macondo",
							fontWeight: 100,
							display: "-webkit-box",
							WebkitLineClamp: 1,
							WebkitBoxOrient: "vertical",
							overflow: "hidden",
							textOverflow: "ellipsis",
							marginTop: "16px",
							marginBottom: "8px",
						}}
					>
						{genres.join(" ⟡ ")}
					</Box>
					{showDescription && (
						<Box
							className="suggestion-carousel-description-text"
							sx={{
								color: "#e5e5e5",
								display: "-webkit-box",
								WebkitLineClamp: 4,
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								textOverflow: "ellipsis",
								marginBottom: "32px",
							}}
						>
							{description}
						</Box>
					)}
					<Button
						className="suggestion-carousel-contents-play-button"
						onClick={onWatchClick}
						sx={{
							backgroundColor: "white",
							border: "none",
							borderRadius: "16px",
							color: "black",
							fontSize: ".875rem",
							fontWeight: 900,
							paddingRight: "16px",
							paddingLeft: "8px",
							paddingY: "0px",
							"&:hover": {
								color: "white",
							},
						}}
					>
						<PlayArrowOutlinedIcon
							fontSize="large"
							sx={{
								color: "inherit",
							}}
						/>
						<Box className="play-button-text">Watch now</Box>
					</Button>
				</Box>
				<div className="suggestion-carousel-overlay"></div>
			</div>
		);
	}
);
