import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
	Box,
	Collapse,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from "@mui/material";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { genreToHeadingMap } from "../SuggestionContainer/SuggestionContainer";
import { pages } from "./TopNav";

interface SideNavProps {
	toggleDrawer: () => void;
}

const GenreSideNav = memo(
	({ open, toggleDrawer }: { open: boolean; toggleDrawer: () => void }) => {
		const navigate = useNavigate();
		return (
			<Collapse in={open} unmountOnExit>
				{Object.keys(genreToHeadingMap)
					.slice(1)
					.map((genre) => (
						<ListItemButton
							key={genre}
							onClick={() => {
								toggleDrawer();
								navigate(`/genre/${genre}`);
							}}
							sx={{
								color: "#ffffff90",
								fontSize: "1rem",
								lineHeight: "1.5rem",
								fontWeight: "500",
							}}
						>
							<ListItemText primary={genre} />
						</ListItemButton>
					))}
			</Collapse>
		);
	}
);

export const SideDrawer = memo(
	(props: {
		isOpen: boolean;
		toggleDrawer: SideNavProps["toggleDrawer"];
	}) => {
		const navigate = useNavigate();
		const [expandGenre, setExpandGenre] = useState(true);
		const commonListProps = {
			color: "#ffffff90",
			fontSize: "1rem",
			lineHeight: "1.5rem",
			fontWeight: "500",
		};
		return (
			<Drawer
				open={props.isOpen}
				onClose={() => props.toggleDrawer()}
				sx={{
					width: 250,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: {
						width: 250,
						top: { xs: 56, sm: 64 },
						boxSizing: "border-box",
						backgroundColor: "rgba(20, 20, 20)",
					},
					display: { lg: "none" },
					zIndex: 1300,
				}}
				disableEnforceFocus
			>
				<Box
					sx={{
						overflow: "auto",
						paddingLeft: {
							xs: "20px",
							sm: "30px",
							md: "60px",
						},
					}}
					role="presentation"
				>
					<List>
						{Object.entries(pages).map(([page, link]) => (
							<ListItem
								key={page}
								sx={{
									paddingLeft: "0px",
								}}
							>
								<ListItemButton
									onClick={() => {
										props.toggleDrawer();
										navigate(link);
									}}
									disableGutters
									sx={{ padding: 0 }}
								>
									<ListItemText
										primary={page}
										primaryTypographyProps={commonListProps}
									/>
								</ListItemButton>
							</ListItem>
						))}
						<ListItem
							key={"genres"}
							sx={{
								paddingLeft: "0px",
							}}
						>
							<ListItemButton
								onClick={(e) => {
									e.preventDefault();
									setExpandGenre(!expandGenre);
								}}
								disableGutters
								sx={{ padding: 0 }}
							>
								<ListItemText
									primary="Genres"
									primaryTypographyProps={commonListProps}
								/>
								{expandGenre ? (
									<ExpandLess sx={commonListProps} />
								) : (
									<ExpandMore sx={commonListProps} />
								)}
							</ListItemButton>
						</ListItem>
						<GenreSideNav
							open={expandGenre}
							toggleDrawer={props.toggleDrawer}
						/>
					</List>
				</Box>
			</Drawer>
		);
	}
);

export const SideNav = memo(({ toggleDrawer }: SideNavProps) => {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				flexGrow: 1,
				display: { xs: "flex", lg: "none" },
			}}
		>
			<IconButton
				size="large"
				aria-label="side navigation"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={() => toggleDrawer()}
				color="inherit"
				sx={{ padding: 0, marginRight: "20px" }}
			>
				<MenuIcon />
			</IconButton>
			<Link
				onClick={(event) => {
					event.preventDefault();
					navigate("/");
				}}
			>
				<Box
					sx={{
						display: { xs: "flex", lg: "none" },
						mr: 1,
						width: "110px",
						height: "30px",
					}}
				>
					<img
						src="https://coverimagecdn.s3.us-west-2.amazonaws.com/AnyAnimeMobileLogo.svg"
						alt="AnyAnime"
					/>
				</Box>
			</Link>
		</Box>
	);
});
