import {
	InfiniteData,
	UseInfiniteQueryResult,
	useInfiniteQuery,
} from "@tanstack/react-query";
import { anyAnimeClient } from "../services/anyanime.service";
import {
	GetAnimeListInput,
	GetAnimeListOutput,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";

export type UseInfiniteGetAnimeResults = UseInfiniteQueryResult<
	InfiniteData<GetAnimeListOutput, unknown>,
	Error
>;

export const useGetAnimeList = ({
	nextToken,
}: GetAnimeListInput): UseInfiniteGetAnimeResults => {
	const results = useInfiniteQuery({
		queryKey: [QUERY_KEYS.useGetAnimeList, nextToken],
		queryFn: async ({ pageParam: nextToken }) => {
			return await anyAnimeClient.getAnimeList({
				nextToken,
			});
		},
		gcTime: 60 * 60 * 1000,
		getNextPageParam: (lastPage) => lastPage.nextToken,
		initialPageParam: "",
	});
	return results;
};
