import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { FootNav } from "./components/Navigation/FootNav";
import { TopNav } from "./components/Navigation/TopNav";
import { WindowWidthProvider } from "./Context/WindowWidth.context";
import { AnimeListPage } from "./Pages/AnimeListPage";
import { DetailsPage } from "./Pages/DetailsPage";
import { GenreResultsPage } from "./Pages/GenreResultsPage";
import Home from "./Pages/Home";
import { NotFoundPage } from "./Pages/NotFoundPage";
import { SearchPage } from "./Pages/SearchPage";
import { TypeResultsPage } from "./Pages/TypeResultsPage";
import { WatchPage } from "./Pages/WatchPage";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const AppRoutes = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/search" element={<SearchPage />} />
			<Route path="/anime/:id" element={<DetailsPage />} />
			<Route path="/watch/:id/:episodeId" element={<WatchPage />} />
			<Route path="/type/:id" element={<TypeResultsPage />} />
			<Route path="/genre/:id" element={<GenreResultsPage />} />
			<Route path="/list" element={<AnimeListPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

const AppLayout = () => (
	<div dir="ltr" style={{ height: "100%" }}>
		<TopNav />
		<AppRoutes />
		<FootNav />
	</div>
);

const App = () => (
	<>
		<Helmet>
			<meta
				httpEquiv="Content-Security-Policy"
				content={`
          default-src 'self' https:;
          script-src 'self' https:;
          img-src https:;
          font-src 'self' https: data:;
          frame-src https://awish.pro https://www.mp4upload.com https://dood.wf https://alions.pro https://hydrax.net https://abysscdn.com https://disqus.com https://*.disqus.com;
          style-src 'self' https: 'unsafe-inline';
        `}
			/>
		</Helmet>
		<ErrorBoundary>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<WindowWidthProvider>
						<AppLayout />
					</WindowWidthProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</ErrorBoundary>
	</>
);

export default App;
