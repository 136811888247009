import {
	InfiniteData,
	UseInfiniteQueryResult,
	useInfiniteQuery,
} from "@tanstack/react-query";
import { anyAnimeClient } from "../services/anyanime.service";
import {
	GetGenreInput,
	GetGenreOutput,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";

export type UseInfiniteGetGenreResults = UseInfiniteQueryResult<
	InfiniteData<GetGenreOutput, unknown>,
	Error
>;

export const useGetGenre = ({
	query,
	nextToken,
}: GetGenreInput): UseInfiniteGetGenreResults => {
	const results = useInfiniteQuery({
		queryKey: [QUERY_KEYS.useGetGenre, query, nextToken],
		queryFn: async ({ pageParam: nextToken }) => {
			return await anyAnimeClient.getGenre({
				query,
				nextToken,
			});
		},
		gcTime: 60 * 60 * 1000,
		getNextPageParam: (lastPage) => lastPage.nextToken,
		initialPageParam: "",
	});
	return results;
};
