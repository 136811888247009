import axios, { AxiosInstance } from "axios";
import {
	DescribeAnimeInput,
	DescribeAnimeOutput,
	DescribeEpisodesInput,
	DescribeEpisodesOutput,
	GetAnimeListInput,
	GetAnimeListOutput,
	GetGenreInput,
	GetGenreOutput,
	GetTypeInput,
	GetTypeOutput,
	GetUpdatesOutput,
	SearchAnimeInput,
	SearchAnimeOutput,
	WatchLinksInput,
	WatchLinksOutput,
	operationNameMap,
} from "./anyanime.service.models";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_ANYANIME_API_BASE_URL,
	timeout: 7000,
});

export class AnyAnimeApiClient {
	readonly axiosClient: AxiosInstance;

	constructor(axiosClient: AxiosInstance) {
		this.axiosClient = axiosClient;
	}

	async axiosPost<T, R>(target: string, request: T): Promise<R> {
		return this.axiosClient
			.post("", request, {
				headers: { "x-requested-with": target },
				timeout: 60000,
			})
			.then((response) => response.data)
			.catch((e) => {
				console.error(e);
				throw new Error(e);
			});
	}

	async axiosGet<T, R>(target: string, request: T): Promise<R> {
		return this.axiosClient.get("", {
			headers: { "x-requested-with": target },
			params: { request },
			timeout: 60000,
		});
	}

	async searchAnime(query: SearchAnimeInput): Promise<SearchAnimeOutput> {
		return this.axiosPost(operationNameMap.searchAnime, query);
	}

	async describeAnime(
		query: DescribeAnimeInput
	): Promise<DescribeAnimeOutput> {
		return this.axiosPost(operationNameMap.describeAnime, query);
	}

	async describeEpisodes(
		query: DescribeEpisodesInput
	): Promise<DescribeEpisodesOutput> {
		return this.axiosPost(operationNameMap.describeEpisodes, query);
	}

	async getUpdates(): Promise<GetUpdatesOutput> {
		return this.axiosPost(operationNameMap.getUpdates, "");
	}

	async getType(query: GetTypeInput): Promise<GetTypeOutput> {
		return this.axiosPost(operationNameMap.getType, query);
	}

	async getGenre(query: GetGenreInput): Promise<GetGenreOutput> {
		return this.axiosPost(operationNameMap.getGenre, query);
	}

	async getAnimeList(query: GetAnimeListInput): Promise<GetAnimeListOutput> {
		return this.axiosPost(operationNameMap.getAnimeList, query);
	}

	async watchLinks(query: WatchLinksInput): Promise<WatchLinksOutput> {
		return this.axiosPost(operationNameMap.watchLinks, query);
	}
}

export const anyAnimeClient = new AnyAnimeApiClient(axiosInstance);
