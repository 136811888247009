import Box from "@mui/material/Box";
import { memo, useContext } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { WindowWidthContext } from "../../Context/WindowWidth.context";
import { SuggestionCarouselContents } from "./SuggestionCarouselContents";
import "./SuggestionCarouselStyle.scss";
import suggestions from "./Suggestions.json";

interface Suggestion {
	link: string;
	bgImageSrc: string;
	bgImageMobileSrc: string;
	logoImageSrc: string;
	genres: string[];
	description: string;
	name: string;
}

export const SuggestionCarousel = memo(() => {
	const { windowWidth } = useContext(WindowWidthContext);

	const carouselClassName =
		windowWidth < 900
			? "suggestion-carousel-mobile"
			: "suggestion-carousel";

	const paginationConfig = {
		el: ".autoplay-progress",
		clickable: true,
		renderBullet: (_: number, className: string) =>
			`<button class="${className}"></button>`,
	};

	return (
		<Swiper
			className={carouselClassName}
			autoplay={{ delay: 4000, disableOnInteraction: true }}
			loop={true}
			pagination={paginationConfig}
			centeredSlides={true}
			modules={[Autoplay, Pagination, Navigation]}
		>
			{suggestions.data.map((info: Suggestion) => (
				<SwiperSlide key={info.link} zoom>
					<SuggestionCarouselContents
						showDescription={windowWidth > 1200}
						isMobile={windowWidth < 900}
						carouselImgSrc={
							windowWidth < 900
								? info.bgImageMobileSrc
								: info.bgImageSrc
						}
						logoImgSrc={info.logoImageSrc}
						genres={info.genres}
						description={info.description}
						watchLink={info.link}
						animeName={info.name}
					/>
				</SwiperSlide>
			))}
			<Box
				className="autoplay-progress"
				slot="container-end"
				sx={{
					paddingInline: { xs: "20px", sm: "30px", md: "60px" },
					bottom: {
						xs: "min(calc(0.25 * 55vi), 60rem)",
						xl: "min(calc(0.35 * 55vi), 60rem)",
					},
					marginLeft: "-8px",
				}}
			></Box>
		</Swiper>
	);
});
