import {
	Box,
	Button,
	Chip,
	Divider,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import { Fragment, memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DetailCardProps } from "./DetailCard";
import {
	DetailsValueWithLabelsMobile,
	ValueWithLabel,
} from "./DetailsValueWithLabels";

interface InfoWrapperMobileProps extends DetailCardProps {
	paddingY?: string;
	paddingX?: string;
	marginTop?: string;
}

export const InfoWrapperMobile = memo(
	({
		animeDetails,
		paddingX,
		paddingY,
		marginTop,
	}: InfoWrapperMobileProps) => {
		const [expanded, setExpanded] = useState(false);

		return (
			<Fragment>
				<Box
					className="detail-page-mobile-info"
					sx={{
						marginTop: marginTop ?? "30px",
						background:
							"linear-gradient(to bottom, rgba(42,44,49,.5) 30px, #0000)",
						paddingInline: paddingX ?? {
							xs: "20px",
							sm: "30px",
							md: "60px",
						},
						paddingY: paddingY ?? "20px",
						display: "flex",
						flexDirection: "column",
						position: "relative",
						zIndex: 2,
						maxHeight: expanded ? "100%" : "30px",
						overflow: "hidden",
						maskImage: expanded
							? ""
							: "linear-gradient(to bottom, #000 30px, #0000)",
					}}
				>
					<DetailsValueWithLabelsMobile animeDetails={animeDetails} />
				</Box>
				<Button
					variant="text"
					sx={{
						display: "flex",
						justifyContent: "start",
						padding: 0,
						color: "#fff",
						userSelect: "none",
						textTransform: "uppercase",
						fontSize: "0.75rem",
						lineHeight: "1rem",
						fontWeight: 900,
						paddingInline: paddingX ?? {
							xs: "20px",
							sm: "30px",
							md: "60px",
						},
						marginTop: "10px",
						backgroundColor: "transparent",
						"&:hover": {
							backgroundColor: "transparent",
						},
					}}
					onClick={() => setExpanded(!expanded)}
				>
					{expanded ? "Fewer details" : "More details"}
				</Button>
			</Fragment>
		);
	}
);

export const InfoWrapperDesktop = memo(({ animeDetails }: DetailCardProps) => {
	const navigate = useNavigate();
	const [expanded, setExpanded] = useState(false);
	const { Plot_Summary, Released, TotalEpisodes, WatchCount, Type, Genre } =
		animeDetails;

	return (
		<Stack
			direction="column"
			spacing={"20px"}
			sx={{
				marginTop: "20px",
				width: {
					Webkit: "-webkit-fill-available",
					Moz: "-moz-available",
					width: "fill-available",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				{Plot_Summary && (
					<Typography
						sx={{
							fontWeight: "400",
							fontSize: "13px",
							lineHeight: "1.4em",
							textSizeAdjust: "none",
							userSelect: "none",
							display: "-webkit-box",
							WebkitBoxOrient: "vertical",
						}}
						color="white"
					>
						{expanded
							? Plot_Summary
							: Plot_Summary.trim().slice(0, 200)}
						{Plot_Summary.length > 200 && (
							<>
								{!expanded && "... "}
								<span
									onClick={() => setExpanded(!expanded)}
									style={{
										fontWeight: "600",
										color: "#a584ff",
										cursor: "pointer",
										textDecoration: "none",
									}}
								>
									{expanded ? " - Less" : " - More"}
								</span>
							</>
						)}
					</Typography>
				)}
			</Box>
			<Stack
				direction="row"
				spacing={1}
				divider={
					<Divider
						orientation="vertical"
						sx={{ borderColor: "white" }}
						flexItem
					/>
				}
				useFlexGap
				sx={{
					flexWrap: "wrap",
					justifyContent: "space-between",
					alignItems: "flex-start",
				}}
			>
				<ValueWithLabel
					value={Released?.toString() || "0000"}
					label={"Released"}
				/>

				<ValueWithLabel
					value={TotalEpisodes?.toString() || "0"}
					label={"Episodes"}
				/>

				{Type && <ValueWithLabel value={Type.trim()} label={"Type"} />}
				<ValueWithLabel
					value={WatchCount?.toString() || "0"}
					label={"Views"}
				/>
			</Stack>
			<Box
				sx={{
					display: "inline-flex",
					flexWrap: "wrap",
				}}
			>
				<Typography
					sx={{
						fontWeight: "800",
						fontSize: "13px",
						lineHeight: "1.5rem",
						textSizeAdjust: "none",
						marginRight: "5px",
					}}
					color="white"
				>
					Genre:
				</Typography>
				{Genre &&
					Genre.map((genre) => (
						<Link
							key={genre}
							onClick={() => navigate(`/genre/${genre}`)}
						>
							<Chip
								sx={{
									color: "white",
									fontSize: "12px",
									lineHeight: "1.1em",
									height: "24px",
									marginRight: "5px",
									marginBottom: "5px",
								}}
								label={genre}
								key={genre}
								variant="outlined"
							/>
						</Link>
					))}
			</Box>
			<ValueWithLabel
				value={animeDetails.Other_Names?.join(", ") || ""}
				label={"Synonyms"}
			/>
		</Stack>
	);
});
