import { Alert, Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { CommentsSection } from "../CommentsSection/CommentsSection";
import { EpisodesList } from "../EpisodesList/EpisodesList";
import { MoreLikeThis } from "../MoreLikeThis/MoreLikeThis";
import { InfoWrapperWatchPage } from "./InfoWrapperWatchPage";
import { VideoAndTitleCard } from "./VideoAndTitleCard";

export const WatchContainerDesktop = memo(() => {
	const { id, episodeId } = useParams();
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

	if (!id || !episodeId) {
		return <Alert severity="error">Wrong URL it seems!!</Alert>;
	}

	return (
		<Box
			className="watch-page-container-desktop"
			sx={{
				display: { xs: "none", md: "flex" },
				marginTop: { xs: "56px", sm: "64px" },
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				paddingInline: { xs: "20px", sm: "30px", md: "60px" },
				paddingTop: "24px",
			}}
		>
			<Box
				sx={{
					display: "grid",
					boxSizing: "border-box",
					gridTemplateColumns: "65% 35%",
					columnCount: "2",
					width: "100%",
				}}
			>
				<Stack direction="column" gap={5}>
					<VideoAndTitleCard episodeId={episodeId} name={id} />
					<InfoWrapperWatchPage name={id} />
					<CommentsSection
						uniqueId={`${id} - ${episodeId}`}
						title={id}
						padding="0px"
						render={isBigScreen}
					/>
				</Stack>
				<Box
					sx={{
						paddingLeft: "30px",
						maxHeight: "inherit",
						overflowY: "auto",
						overflowX: "hidden",
					}}
				>
					<EpisodesList
						animeName={id}
						paddingX="0px"
						paddingY="0px"
						disableEpisodeSearch
						activeEpisode={episodeId}
						navigateToActiveEpisode
					/>
					<MoreLikeThis
						baseAnime={id}
						padding="0px"
						columnCount={2}
					/>
				</Box>
			</Box>
		</Box>
	);
});
