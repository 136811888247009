import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Box, Chip } from "@mui/material";
import { Fragment, memo } from "react";
import { DetailCardProps } from "./DetailCard";
import Dot from "./Dot";

const ChipLayer = memo(({ animeDetails }: DetailCardProps) => {
	return (
		<Fragment>
			<Box
				sx={{
					display: "inline-flex",
					marginTop: "30px",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "row",
					zIndex: 2,
				}}
			>
				<Chip
					icon={
						<PlayCircleOutlineIcon
							sx={{ color: "inherit !important" }}
						/>
					}
					label={animeDetails.TotalEpisodes}
					variant="filled"
					size="medium"
					sx={{
						backgroundColor: "#b9e7ff",
						color: "black",
						borderRadius: "16px 0 0 16px",
					}}
				/>
				<Chip
					icon={
						<AccessTimeIcon sx={{ color: "inherit !important" }} />
					}
					label={animeDetails.Released}
					variant="filled"
					size="medium"
					sx={{
						backgroundColor: "#b0e3af",
						color: "black",
						borderRadius: "0",
					}}
				/>
				<Chip
					icon={
						<FastForwardIcon sx={{ color: "inherit !important" }} />
					}
					label={animeDetails.WatchCount}
					variant="filled"
					size="medium"
					sx={{
						backgroundColor: "rgb(255, 239, 213)",
						color: "black",
						borderRadius: "0 16px 16px 0",
					}}
				/>
				<Box
					sx={{
						display: { xs: "none", sm: "inline-flex" },
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
					}}
				>
					<Dot />
					<Box sx={{ textAlign: "center", color: "#a0a0a0" }}>
						{animeDetails.Type}
					</Box>
					<Dot />
					<Box sx={{ textAlign: "center", color: "#a0a0a0" }}>
						{animeDetails.Name.includes("(Dub)") ? "DUB" : "SUB"}
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: { xs: "inline-flex", sm: "none" },
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "row",
					marginTop: "20px",
					zIndex: 2,
				}}
			>
				<Box sx={{ textAlign: "center", color: "#a0a0a0" }}>
					{animeDetails.Type}
				</Box>
				<Dot />
				<Box sx={{ textAlign: "center", color: "#a0a0a0" }}>
					{animeDetails.Name.includes("(Dub)") ? "DUB" : "SUB"}
				</Box>
			</Box>
		</Fragment>
	);
});

export default ChipLayer;
