import {
	Fragment,
	memo,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";

import CloudIcon from "@mui/icons-material/Cloud";
import DownloadIcon from "@mui/icons-material/Download";
import {
	Alert,
	Box,
	Button,
	Container,
	Dialog,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import { useWatchLinks } from "../../hooks/useWatchLinks";
import { LinkDetails } from "../../model/anime-detail";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

interface VideoCardProps {
	name: string;
	episodeId: string;
}

interface LinkSelectionProps {
	linkDetails?: LinkDetails[];
	onServerSelect: (linkDetail: LinkDetails) => void;
	text: string;
	icon: ReactNode;
}

export const LinkSelection = memo(
	({ linkDetails, onServerSelect, text, icon }: LinkSelectionProps) => {
		const [open, setOpen] = useState(false);

		const handleClickOpen = () => {
			setOpen(true);
		};

		const handleClose = () => {
			setOpen(false);
		};
		if (linkDetails) {
			return (
				<>
					<Button
						variant="contained"
						startIcon={icon}
						onClick={handleClickOpen}
						sx={{
							borderRadius: "20px",
							backgroundColor: "white",
							height: "1.75rem",
							fontSize: ".75rem",
							color: "black",
							"&:hover": {
								color: "white",
								backgroundColor: "black",
							},
							"& .MuiButton-icon": {
								margin: { xs: 0, sm: "0px 8px 0px -4px" },
							},
						}}
					>
						<Typography
							component="span"
							sx={{
								display: { xs: "none", sm: "inline" }, // Hide text on xs, show on sm and larger
							}}
						>
							{text}
						</Typography>
					</Button>
					<Dialog onClose={handleClose} open={open}>
						<List sx={{ pt: 0 }}>
							{linkDetails.map((linkDetail) => (
								<ListItem
									disableGutters
									key={linkDetail.ServerName}
								>
									<ListItemButton
										onClick={(e) => {
											e.preventDefault();
											onServerSelect(linkDetail);
											handleClose();
										}}
									>
										<ListItemText
											primary={linkDetail.ServerName}
										/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Dialog>
				</>
			);
		}
		return null;
	}
);

export const VideoAndTitleCard = memo(({ name, episodeId }: VideoCardProps) => {
	const [streamLink, setStreamLink] = useState<LinkDetails | undefined>(
		undefined
	);
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const {
		data: episodeDetails,
		isError: isEpisodeDetailsError,
		isLoading: isEpisodeDetailLading,
	} = useWatchLinks({
		query: JSON.stringify({ name, episodeNumber: episodeId }) ?? "",
	});

	useEffect(() => {
		episodeDetails && setStreamLink(episodeDetails.data.StreamLinks[0]);
	}, [episodeDetails]);

	const handleServerLinkChange = useCallback((newStreamLink: LinkDetails) => {
		setStreamLink(newStreamLink);
	}, []);

	const handleDownloadClick = useCallback((downloadLink: LinkDetails) => {
		window.open(downloadLink.Link, "_blank");
	}, []);

	const handleIframeLoaded = () => {
		setIframeLoaded(true);
	};

	let content;
	if (isEpisodeDetailLading) {
		content = <LoadingSpinner />;
	}
	if (isEpisodeDetailsError) {
		content = <Alert severity="error">Something went wrong!!</Alert>;
	}
	if (streamLink) {
		content = (
			<Fragment>
				<Container
					className="video-card-container"
					disableGutters
					maxWidth={false}
					sx={{
						position: "relative",
						aspectRatio: "16 / 9",
						width: "100%",
						minHeight: { xs: "240px", md: "500px" },
					}}
				>
					<Stack
						direction="column"
						gap={2}
						sx={{ height: "100%", width: "inherit" }}
					>
						<Paper
							sx={{
								width: "100%",
								height: "100%",
								backgroundColor: "transparent",
								"& iframe": {
									width: "100%",
									height: "100%",
									border: "none",
								},
								borderRadius: "20px",
							}}
						>
							<iframe
								key={`${episodeId} - ${name} - ${streamLink.ServerName}`}
								{...(["DoodStream", "SERVER HYDRAX"].includes(
									streamLink.ServerName
								)
									? {}
									: {
											sandbox:
												"allow-same-origin allow-scripts",
									  })}
								id="video-player"
								allowFullScreen
								src={streamLink.Link}
								title={`Ep ${episodeId} - ${name} : ${streamLink.ServerName}`}
								onLoad={handleIframeLoaded}
								style={{
									borderRadius: "20px",
									filter: iframeLoaded
										? "none"
										: "blur(100px)",
									transition: "filter 0.5s ease-in-out",
									backgroundColor: iframeLoaded
										? "transparent"
										: "#7fffd4",
								}}
							></iframe>
						</Paper>
						<Box
							className="watch-page-episode-title-and-controls"
							sx={{
								display: "grid",
								boxSizing: "border-box",
								gridTemplateColumns: "35% 65%",
								columnCount: "2",
								width: "100%",
								alignItems: "center",
							}}
						>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "700",
									lineHeight: {
										xs: "1.25rem",
										sm: "1.475rem",
										md: "1.75rem",
									},
									fontSize: {
										xs: "1rem",
										sm: "1.25rem",
										md: "1.375rem",
									},
									textRendering: "optimizeLegibility",
									textWrap: "pretty",
								}}
							>
								Episode {episodeId}
							</Typography>
							<Stack
								direction="row"
								gap={1}
								sx={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<LinkSelection
									linkDetails={
										episodeDetails?.data.StreamLinks
									}
									onServerSelect={handleServerLinkChange}
									text={streamLink.ServerName}
									icon={<CloudIcon fontSize="small" />}
								/>
								<LinkSelection
									linkDetails={
										episodeDetails?.data.DownloadLinks
									}
									onServerSelect={handleDownloadClick}
									text="Download"
									icon={<DownloadIcon fontSize="small" />}
								/>
							</Stack>
						</Box>
					</Stack>
				</Container>
			</Fragment>
		);
	}

	return (
		<Stack gap={3} direction="column" sx={{ width: "100%" }}>
			{content}
		</Stack>
	);
});
