import { createContext, useCallback, useEffect, useReducer } from "react";
import { useGetUpdates } from "../hooks/useGetUpdates";
import { GetUpdatesOutput } from "../services/anyanime.service.models";

type Action =
	| { type: "FETCH_INIT" }
	| { type: "FETCH_SUCCESS"; payload: GetUpdatesOutput }
	| { type: "FETCH_ERROR" };

interface State {
	data: GetUpdatesOutput | undefined;
	isError: boolean;
	isLoading: boolean;
	hasFetched: boolean;
}

const initialState: State = {
	data: undefined,
	isError: false,
	isLoading: false,
	hasFetched: false,
};

const dataFetchReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "FETCH_INIT":
			return { ...state, isLoading: true, isError: false };
		case "FETCH_SUCCESS":
			return {
				...state,
				isLoading: false,
				data: action.payload,
				hasFetched: true,
			};
		case "FETCH_ERROR":
			return { ...state, isLoading: false, isError: true };
		default:
			return state;
	}
};

export interface SuggestionSwiperContextType {
	state: State;
	fetchData: () => void;
}

export const SuggestionSwiperContext =
	createContext<SuggestionSwiperContextType>({
		state: initialState,
		fetchData: () => {},
	});

export const SuggestionSwiperProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [state, dispatch] = useReducer(dataFetchReducer, initialState);

	const { data, isError, refetch } = useGetUpdates();

	const fetchData = useCallback(() => {
		dispatch({ type: "FETCH_INIT" });
		refetch();
	}, [refetch]);

	useEffect(() => {
		if (!state.hasFetched && !data) {
			fetchData();
		} else if (isError) {
			dispatch({ type: "FETCH_ERROR" });
		} else if (data) {
			dispatch({ type: "FETCH_SUCCESS", payload: data });
		}
	}, [data, fetchData, isError, state.hasFetched]);

	const contextValue = {
		state,
		fetchData,
	};

	return (
		<SuggestionSwiperContext.Provider value={contextValue}>
			{children}
		</SuggestionSwiperContext.Provider>
	);
};
