import { Box, Chip, Link, Skeleton } from "@mui/material";
import { memo, useState } from "react";

import { useNavigate } from "react-router-dom";
import "./imageAndTitleCard.scss";

interface ImageAndTitleCardProps {
	imgUrl: string;
	title: string;
	totalEpisodes?: number;
	isUpdate?: boolean;
}

export const ImageAndTitleCard = memo(
	({ imgUrl, title, totalEpisodes, isUpdate }: ImageAndTitleCardProps) => {
		const navigate = useNavigate();

		const handleOnClick = () => {
			navigate(
				isUpdate
					? `/watch/${encodeURIComponent(title)}/${totalEpisodes}`
					: `/anime/${encodeURIComponent(title)}`
			);
		};

		const [imageLoaded, setImageLoaded] = useState(false);
		const handleImageLoaded = () => {
			setImageLoaded(true);
		};

		return (
			<Link onClick={handleOnClick}>
				<Box
					sx={{
						display: "grid",
						height: "100%",
						maxHeight: "730px",
						boxSizing: "border-box",
						gridTemplateRows: { xs: "78% 20%", xl: "83% 15%" },
					}}
				>
					<Box
						sx={{
							gridRowStart: 1,
							gridRowEnd: 2,
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
							overflow: "hidden",
							borderRadius: "8px 8px 8px 8px",
							position: "relative",
						}}
					>
						<img
							className="image-card"
							src={imgUrl}
							alt="animeSwiperCard"
							loading="lazy"
							onLoad={handleImageLoaded}
							style={{
								visibility: imageLoaded ? "visible" : "hidden",
							}}
						></img>
						<Skeleton
							variant="rectangular"
							animation="pulse"
							height={300}
							sx={{
								visibility: imageLoaded ? "hidden" : "visible",
								borderRadius: "8px 8px 8px 8px",
							}}
						/>

						<div className="image-card-overlay">
							<div
								className="text-bottom-left"
								style={{
									backgroundColor: "#b0e3af",
									visibility: totalEpisodes
										? "visible"
										: "hidden",
								}}
							>
								<Chip
									label={`EP${totalEpisodes}`}
									sx={{
										height: "20px",
										"& .MuiChip-label": {
											padding: "5px",
											fontWeight: 700,
										},
									}}
								/>
							</div>
							<div
								className="text-bottom-right"
								style={{ backgroundColor: "#b9e7ff" }}
							>
								<Chip
									label={
										title.includes("(Dub)") ? "DUB" : "SUB"
									}
									sx={{
										height: "20px",
										"& .MuiChip-label": {
											padding: "5px",
											fontWeight: 700,
										},
									}}
								/>
							</div>
						</div>
					</Box>

					<Box
						sx={{
							paddingTop: "10px",
							gridRowStart: 2,
							gridRowEnd: 3,
						}}
					>
						<Box
							sx={{
								display: "-webkit-inline-box",
								WebkitLineClamp: 3,
								WebkitBoxOrient: "vertical",
								color: "white",
								textOverflow: "ellipsis",
								fontSize: ".875rem",
								overflow: "hidden",
								overflowWrap: "break-word",
							}}
						>
							{title}
						</Box>
					</Box>
				</Box>
			</Link>
		);
	}
);
