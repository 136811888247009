import {
	InfiniteData,
	UseInfiniteQueryResult,
	useInfiniteQuery,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { anyAnimeClient } from "../services/anyanime.service";
import {
	SearchAnimeInput,
	SearchAnimeOutput,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";

export type UseInfiniteSearchAnimeResults = UseInfiniteQueryResult<
	InfiniteData<SearchAnimeOutput, unknown>,
	Error
>;

export const useSearchAnime = ({
	query,
	nextToken,
}: SearchAnimeInput): UseInfiniteSearchAnimeResults => {
	const [debouncedQuery, setDebouncedQuery] = useState(query);

	useEffect(() => {
		if (query !== debouncedQuery) {
			const timerId = setTimeout(() => setDebouncedQuery(query), 300);
			return () => clearTimeout(timerId);
		}
	}, [debouncedQuery, query]);

	const results = useInfiniteQuery({
		queryKey: [QUERY_KEYS.useSearchAnime, debouncedQuery, nextToken],
		queryFn: async ({ pageParam: nextToken }) => {
			return await anyAnimeClient.searchAnime({
				query: debouncedQuery,
				nextToken,
			});
		},
		gcTime: 60 * 60 * 1000,
		getNextPageParam: (lastPage) => lastPage?.nextToken,
		initialPageParam: "",
	});
	return results;
};
