import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
	DescribeAnimeInput,
	DescribeAnimeOutput,
} from "../services/anyanime.service.models";
import { QUERY_KEYS } from "./query-keys";
import { anyAnimeClient } from "../services/anyanime.service";

export type UseDescribeAnimeResults = UseQueryResult<
	DescribeAnimeOutput,
	unknown
>;

export const useDescribeAnime = ({
	query,
}: DescribeAnimeInput): UseDescribeAnimeResults => {
	return useQuery({
		queryKey: [QUERY_KEYS.useDescribeAnime, query],
		queryFn: async () => {
			return await anyAnimeClient.describeAnime({
				query,
			});
		},
		gcTime: 60 * 60 * 1000,
	});
};
