import { Fragment, memo } from "react";
import { AnimeDetail } from "../../model/anime-detail";
import DetailCardDesktop from "./DetailCardDesktop";
import DetailCardMobile from "./DetailCardMobile";

export interface DetailCardProps {
	animeDetails: Omit<AnimeDetail, "Episodes">;
}

export const DetailCard = memo(({ animeDetails }: DetailCardProps) => {
	return (
		<Fragment>
			<DetailCardMobile animeDetails={animeDetails} />
			<DetailCardDesktop animeDetails={animeDetails} />
		</Fragment>
	);
});
