// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Macondo&family=Satisfy&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Macondo&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Satisfy&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body,html{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:#141414;color:#fff;margin:0;padding:0;cursor:default;font-family:"Open Sans",Helvetica,sans-serif;font-optical-sizing:auto;font-style:normal;font-variation-settings:"wdth" 100;font-weight:500;line-height:1.2;width:100%}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA,KACC,yBAAA,CACA,6BAAA,CAGD,UAEC,kCAAA,CACA,iCAAA,CACA,kBAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA,CACA,cAAA,CACA,4CAAA,CACA,wBAAA,CACA,iBAAA,CACA,kCAAA,CACA,eAAA,CACA,eAAA,CACA,UAAA","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Macondo&family=Satisfy&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Macondo&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Satisfy&display=swap\");\n\nhtml {\n\t-ms-text-size-adjust: 100%;\n\t-webkit-text-size-adjust: 100%;\n}\n\nbody,\nhtml {\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n\tbackground: #141414;\n\tcolor: #fff;\n\tmargin: 0;\n\tpadding: 0;\n\tcursor: default;\n\tfont-family: \"Open Sans\", Helvetica, sans-serif;\n\tfont-optical-sizing: auto;\n\tfont-style: normal;\n\tfont-variation-settings: \"wdth\" 100;\n\tfont-weight: 500;\n\tline-height: 1.2;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
