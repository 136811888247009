import { Alert, Avatar, Link, Stack } from "@mui/material";
import { Fragment, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDescribeAnime } from "../../hooks/useDescribeAnime";
import { InfoWrapperMobile } from "../DetailsContainer/InfoWrapper";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

interface InfoWrapperWatchPageProps {
	name: string;
}

export const InfoWrapperWatchPage = memo(
	({ name }: InfoWrapperWatchPageProps) => {
		const navigate = useNavigate();
		const {
			data: animeDetails,
			isError,
			isLoading,
		} = useDescribeAnime({ query: name });

		const onTitleClick = () =>
			navigate(`/anime/${encodeURIComponent(name)}`);

		let content;
		if (isLoading) {
			content = <LoadingSpinner />;
		}
		if (isError) {
			content = <Alert severity="error">Something went wrong!!</Alert>;
		}

		if (animeDetails?.data) {
			const { Name, Image } = animeDetails.data;
			content = (
				<Fragment>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						gap={2}
					>
						<Avatar
							variant="square"
							sx={{ height: "100%" }}
							alt={Name}
							src={Image}
						/>
						<Link
							variant="h5"
							onClick={onTitleClick}
							sx={{
								color: "white",
								textDecoration: "none",
								fontWeight: "700",
								lineHeight: {
									xs: "1.25rem",
									sm: "1.475rem",
									md: "1.75rem",
								},
								fontSize: {
									xs: "1rem",
									sm: "1.25rem",
									md: "1.375rem",
								},
								textRendering: "optimizeLegibility",
								textWrap: "pretty",
								"&:hover": {
									color: "white",
									textDecoration: "none",
								},
								cursor: "pointer",
							}}
						>
							{Name}
						</Link>
					</Stack>
					<InfoWrapperMobile
						animeDetails={animeDetails.data}
						paddingY="0"
						paddingX="0"
						marginTop="0"
					/>
				</Fragment>
			);
		}
		return (
			<Stack
				direction="column"
				gap={3}
				className="watch-page-anime-title"
				sx={{
					boxSizing: "border-box",
					width: "100%",
					borderRadius: "12px",
					backgroundColor: "rgba(255,255,255,0.1)",
					padding: "12px",
				}}
			>
				{content}
			</Stack>
		);
	}
);
