export const QUERY_KEYS = {
	useSearchAnime: "SearchAnime",
	useDescribeAnime: "DescribeAnime",
	useWatchLinks: "WatchLinks",
	useDescribeEpisodes: "DescribeEpisodes",
	useGetUpdates: "GetUpdates",
	useGetType: "GetType",
	useGetGenre: "GetGenre",
	useGetAnimeList: "GetAnimeList",
};
