import { FreeMode, Mousewheel, Navigation } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import { Box } from "@mui/material";
import { memo, ReactNode, useContext } from "react";
import "swiper/scss";
import "swiper/scss/navigation";
import { WindowWidthContext } from "../../Context/WindowWidth.context";
import { UpdateListResult } from "../../services/anyanime.service.models";
import { ImageAndTitleCard } from "../ImageAndTitleCard/ImageAndTitleCard";
import "./suggestionSwiper.scss";
interface SuggestionSwiperProps {
	headingName: string | ReactNode;
	showsList: UpdateListResult[];
	isUpdate?: boolean;
}

function animeCard(show: UpdateListResult, isUpdate?: boolean) {
	return (
		<SwiperSlide className="suggestion-swiper-slide">
			<ImageAndTitleCard
				imgUrl={show.Image}
				title={show.AnimeName ?? show.Name}
				totalEpisodes={show.EpisodeNumber ?? show.TotalEpisodes}
				isUpdate={isUpdate}
			/>
		</SwiperSlide>
	);
}

export const SuggestionSwiper = memo(
	({ headingName, showsList, isUpdate }: SuggestionSwiperProps) => {
		const { windowWidth } = useContext(WindowWidthContext);

		const jumpByIndex = (swiper: SwiperClass, jumpIndex: number) => {
			swiper.slideTo(swiper.activeIndex + jumpIndex);
		};

		const getPaddingInline = () => {
			if (windowWidth < 900) {
				return "30px";
			} else {
				return "60px";
			}
		};
		return (
			<Box className="suggestion-swiper-row">
				<Box
					className="suggestion-swiper-heading"
					sx={{
						fontSize: "1.375rem",
						lineHeight: "1.75rem",
						verticalAlign: "bottom",
						fontWeight: 700,
						marginBottom: "min(2vi, 20px)",
						textRendering: "optimizeLegibility",
						marginLeft: { xs: "30px", md: "60px" },
					}}
				>
					{headingName}
				</Box>
				<Swiper
					className="suggestion-swiper"
					style={{
						paddingLeft: getPaddingInline(),
						paddingRight: getPaddingInline(),
						boxSizing: "border-box",
						display: "grid",
						minHeight: "300px",
					}}
					spaceBetween={windowWidth <= 600 ? 10 : 20}
					slidesPerView="auto"
					breakpoints={{
						240: {
							slidesPerView: 2,
						},
						500: {
							slidesPerView: 3,
						},
						900: {
							slidesPerView: 4,
						},
						1540: {
							slidesPerView: 5,
						},
						1920: {
							slidesPerView: 6,
						},
						2500: {
							slidesPerView: 7,
						},
					}}
					freeMode={{ sticky: true }}
					mousewheel={{ forceToAxis: true }}
					navigation={windowWidth > 900 ? true : false}
					modules={[FreeMode, Navigation, Mousewheel]}
					watchSlidesProgress
					onNavigationNext={(swiper: SwiperClass) =>
						jumpByIndex(swiper, swiper.slidesPerViewDynamic() - 1)
					}
					onNavigationPrev={(swiper: SwiperClass) =>
						jumpByIndex(swiper, -swiper.slidesPerViewDynamic() + 1)
					}
				>
					{showsList.map((show) => animeCard(show, isUpdate))}
				</Swiper>
			</Box>
		);
	}
);
