import { Alert, Box, ImageList } from "@mui/material";
import debounce from "lodash.debounce";
import { Fragment, memo, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { WindowWidthContext } from "../../Context/WindowWidth.context";
import { useGetType } from "../../hooks/useGetType";
import { GetTypeResult } from "../../services/anyanime.service.models";
import { ImageAndTitleCard } from "../ImageAndTitleCard/ImageAndTitleCard";
import { ImageAndTitleCardSkeleton } from "../LoadingSpinner/ImageAndTitleCardSkeleton";

const idToTitleMap: Record<string, string> = {
	Movie: "Movies",
	OVA: "OVAs",
	ONA: "ONAs",
	Special: "Specials",
	New: "New & Popular",
};

export const TypeResultsContainer = memo(() => {
	const { id } = useParams();
	const { windowWidth } = useContext(WindowWidthContext);

	const {
		data: typeResults,
		isError,
		isLoading,
		isFetching,
		isRefetching,
		hasNextPage,
		fetchNextPage,
		refetch,
	} = useGetType({ query: id ?? "" });

	useEffect(() => {
		const fetchMoreData = async () => {
			if (!(isLoading || isFetching) && hasNextPage) {
				await fetchNextPage();
			}
		};
		const handleScroll = debounce(() => {
			const { scrollTop, clientHeight, scrollHeight } =
				document.documentElement;
			const isMobile = window.innerWidth <= 768;
			const scrollOffset = isMobile ? 1000 : 750;
			if (
				scrollTop + clientHeight >= scrollHeight - scrollOffset &&
				!(isLoading || isFetching)
			) {
				fetchMoreData();
			}
		}, 10);

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [fetchNextPage, hasNextPage, isFetching, isLoading]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		refetch();
	}, [id, refetch]);

	const getColumnsCount = () => {
		if (windowWidth < 500) {
			return 2;
		} else if (windowWidth >= 500 && windowWidth < 900) {
			return 3;
		} else if (windowWidth >= 900 && windowWidth < 1240) {
			return 4;
		} else if (windowWidth >= 1240 && windowWidth < 1540) {
			return 5;
		} else if (windowWidth >= 1540 && windowWidth < 1920) {
			return 6;
		} else if (windowWidth >= 1920) {
			return 7;
		}
	};

	let content;

	if ((isLoading || isFetching || isRefetching) && !typeResults) {
		content = <ImageAndTitleCardSkeleton />;
	}
	if (isError) {
		content = <Alert severity="error">Something went wrong!!</Alert>;
	}

	if (typeResults) {
		const results = typeResults.pages.reduce(
			(prev, curr) => [...prev, ...(curr.data ?? [])],
			[] as GetTypeResult[]
		);
		content = (
			<Fragment>
				<ImageList
					className="type-results-list"
					sx={{
						overflow: "hidden",
						gap: `2.5rem ${
							windowWidth <= 600 ? "10px" : "20px"
						} !important`,
						minHeight: "300px",
						marginTop: "20px",
					}}
					cols={getColumnsCount()}
				>
					{results.map((detail) => (
						<ImageAndTitleCard
							key={detail.Name}
							imgUrl={detail.Image}
							title={detail.Name}
							totalEpisodes={detail.TotalEpisodes}
						/>
					))}
				</ImageList>
				{hasNextPage && <ImageAndTitleCardSkeleton />}
			</Fragment>
		);
	}
	return (
		<Box
			className="type-container"
			sx={{
				marginTop: { xs: "56px", sm: "64px" },
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				paddingInline: { xs: "20px", sm: "30px", md: "60px" },
				display: "flex",
			}}
		>
			<Box
				sx={{
					WebkitBackgroundClip: "text !important",
					backgroundClip: "text !important",
					WebkitTextFillColor: "transparent",
					color: "transparent",
					display: "inline-block",
					position: "relative",
					width: "100%",
					fontSize: "1.5rem",
					marginTop: "24px",
					background:
						"linear-gradient(74deg, #4285f4 0, #9b72cb 9%, #d96570 20%, #d96570 24%, #9b72cb 35%, #4285f4 44%, #9b72cb 50%, #d96570 56%, #4285f4 75%, #9b72cb 100%)",
				}}
			>
				{id ? idToTitleMap[id] : id}
			</Box>
			{content}
		</Box>
	);
});
