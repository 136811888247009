import { Button } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { memo } from "react";

interface EpisodeNumberPillProps {
	epNumber: string;
	isSearched: boolean;
	isActive?: boolean;
	onClick: (ep: string) => void;
}

export const EpisodeNumberPill = memo((props: EpisodeNumberPillProps) => {
	const buttonStyle: SxProps<Theme> = {
		color: props.isActive ? "#141414" : "rgba(255, 255, 255)",
		backgroundColor: props.isActive ? "rgb(255, 239, 213)" : "#a0a0a066",
		flex: 1,
		fontWeight: 700,
		...(props.isSearched
			? {
					"&::before": {
						content: '""',
						width: "100%",
						height: "100%",
						position: "absolute",
						background:
							"radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%)",
						borderRadius: "inherit",
						animation: "blinkRipple 1.5s infinite",
					},
					"@keyframes blinkRipple": {
						"0%": {
							transform: "scale(1)",
							opacity: 0,
						},
						"50%": {
							transform: "scale(0.5)",
							opacity: 1,
						},
					},
					"&:hover::before": {
						transform: "scale(1)",
					},
			  }
			: {}),
		"&:hover": {
			color: props.isActive ? "#141414" : "rgba(255, 255, 255, 0.8)",
			backgroundColor: props.isActive
				? "rgb(255, 239, 213)"
				: "#a0a0a0db",
		},
	};

	return (
		<Button
			id={`episode-pill-${props.epNumber}`}
			sx={buttonStyle}
			onClick={props.onClick.bind(null, props.epNumber)}
		>
			{props.epNumber}
		</Button>
	);
});
