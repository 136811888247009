import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import debounce from "lodash.debounce";
import { memo, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { WindowWidthContext } from "../../Context/WindowWidth.context";
import { useSearchAnime } from "../../hooks/useSearchAnime";
import { SearchAnimeResult } from "../../services/anyanime.service.models";
import { ImageAndTitleCard } from "../ImageAndTitleCard/ImageAndTitleCard";
import { ImageAndTitleCardSkeleton } from "../LoadingSpinner/ImageAndTitleCardSkeleton";

export const SearchContainer = memo(() => {
	const location = useLocation();
	const { windowWidth } = useContext(WindowWidthContext);

	const searchParams = new URLSearchParams(location.search);
	const query = searchParams.get("query");

	const { data, isError, isLoading, isFetching, fetchNextPage, hasNextPage } =
		useSearchAnime({
			query: query?.trim() ?? "",
		});

	useEffect(() => {
		const fetchMoreData = async () => {
			if (!(isLoading || isFetching) && hasNextPage) {
				await fetchNextPage();
			}
		};
		const handleScroll = debounce(() => {
			const { scrollTop, clientHeight, scrollHeight } =
				document.documentElement;
			const isMobile = window.innerWidth <= 768;
			const scrollOffset = isMobile ? 1000 : 750;
			if (
				scrollTop + clientHeight >= scrollHeight - scrollOffset &&
				!(isLoading || isFetching)
			) {
				fetchMoreData();
			}
		}, 10);

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
			handleScroll.cancel();
		};
	}, [fetchNextPage, hasNextPage, isFetching, isLoading]);

	const getColumnsCount = useMemo(() => {
		if (windowWidth < 500) {
			return 2;
		} else if (windowWidth >= 500 && windowWidth < 900) {
			return 3;
		} else if (windowWidth >= 900 && windowWidth < 1240) {
			return 4;
		} else if (windowWidth >= 1240 && windowWidth < 1540) {
			return 5;
		} else if (windowWidth >= 1540 && windowWidth < 1920) {
			return 6;
		} else if (windowWidth >= 1920) {
			return 7;
		} else {
			return 8;
		}
	}, [windowWidth]);

	let content;

	if ((isLoading || isFetching) && !data) {
		content = <ImageAndTitleCardSkeleton />;
	}
	if (isError) {
		content = <Alert severity="error">Something went wrong!!</Alert>;
	}

	if (data) {
		const searchResults = data.pages.reduce(
			(prev, curr) => [...prev, ...(curr.data ?? [])],
			[] as SearchAnimeResult[]
		);
		content = (
			<>
				<ImageList
					className="search-results-list"
					sx={{
						overflow: "hidden",
						gap: `2.5rem ${
							windowWidth <= 600 ? "10px" : "20px"
						} !important`,
						minHeight: "300px",
						marginTop: "20px",
					}}
					cols={getColumnsCount}
				>
					{searchResults.map((detail) => (
						<ImageAndTitleCard
							key={detail.Name}
							imgUrl={detail.Image}
							title={detail.Name}
							totalEpisodes={detail.TotalEpisodes}
						/>
					))}
				</ImageList>
				{hasNextPage && <ImageAndTitleCardSkeleton />}
			</>
		);
	}
	return (
		<Box
			className="search-container"
			sx={{
				marginTop: { xs: "56px", sm: "64px" },
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				paddingInline: { xs: "20px", sm: "30px", md: "60px" },
				display: "flex",
			}}
		>
			<Box
				sx={{
					color: "#696969",
					fontSize: "max(20px, min(2.5vw, 45px))",
					fontWeight: "500",
					marginTop: "10px",
					paddingY: "5px",
					alignSelf: "start",
				}}
			>
				Search results for:{" "}
				<Box
					sx={{
						WebkitBackgroundClip: "text !important",
						backgroundClip: "text !important",
						WebkitTextFillColor: "transparent",
						backgroundSize: "400% 100%",
						color: "transparent",
						display: "inline-block",
						position: "relative",
						background:
							"linear-gradient(74deg, #4285f4 0, #9b72cb 9%, #d96570 20%, #d96570 24%, #9b72cb 35%, #4285f4 44%, #9b72cb 50%, #d96570 56%, #4285f4 75%, #9b72cb 100%)",
					}}
				>
					{query}
				</Box>
			</Box>
			{content}
		</Box>
	);
});
