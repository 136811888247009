import Box from "@mui/material/Box";

const Dot = () => (
	<Box
		sx={{
			width: "4px",
			height: "4px",
			borderRadius: "50%",
			background: "rgb(255,255,255, 0.7)",
			display: "inline-block",
			marginX: "10px",
		}}
	/>
);

export default Dot;
