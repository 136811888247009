import { AnimeDetail, LinkDetails } from "../model/anime-detail";

export const operationNameMap = {
	searchAnime: "SearchAnime",
	describeAnime: "DescribeAnime",
	describeEpisodes: "DescribeEpisodes",
	watchLinks: "WatchLinks",
	getUpdates: "GetUpdates",
	getType: "GetType",
	getGenre: "GetGenre",
	getAnimeList: "GetAnimeList",
};

export interface SearchAnimeInput {
	query: string;
	nextToken?: string;
}

export type SearchAnimeResult = Omit<
	AnimeDetail,
	"Plot Summary" | "Released" | "Other name" | "Episodes"
>;

export interface SearchAnimeOutput {
	data: SearchAnimeResult[];
	nextToken?: string;
}

export interface DescribeAnimeInput {
	query: string;
}
export interface DescribeAnimeOutput {
	data: Omit<AnimeDetail, "Episodes">;
}

export interface DescribeEpisodesInput {
	query: string;
}

export interface DescribeEpisodesOutput {
	data: string[];
}

export interface UpdateListResult
	extends Pick<AnimeDetail, "Name" | "TotalEpisodes" | "Image" | "Released"> {
	Date?: string;
	EpisodeNumber?: number;
	AnimeName?: string;
}

export interface GetUpdatesOutput {
	data: Record<string, UpdateListResult[]>;
}

export interface WatchLinksInput {
	query: string;
}

export interface WatchLinksOutput {
	data: {
		EpisodeNumber: number;
		AnimeName: string;
		DownloadLinks: LinkDetails[];
		StreamLinks: LinkDetails[];
	};
}

export interface GetTypeInput {
	query: string;
	nextToken?: string;
}

export type GetTypeResult = Pick<
	AnimeDetail,
	"Name" | "TotalEpisodes" | "Image" | "Released" | "Type"
>;

export interface GetTypeOutput {
	data: GetTypeResult[];
	nextToken?: string;
}

export interface GetGenreInput {
	query: string;
	nextToken?: string;
}

export type GetGenreResult = Pick<
	AnimeDetail,
	"Name" | "TotalEpisodes" | "Image" | "Released" | "Genre"
>;

export interface GetGenreOutput {
	data: GetGenreResult[];
	nextToken?: string;
}

export interface GetAnimeListInput {
	nextToken?: string;
}

export interface GetAnimeListOutput {
	data: Pick<AnimeDetail, "Name">[];
	nextToken?: string;
}
