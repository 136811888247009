import { Box, ImageList, Skeleton, Stack } from "@mui/material";
import { memo, useContext, useMemo } from "react";
import { WindowWidthContext } from "../../Context/WindowWidth.context";

export const ImageAndTitleCardSkeleton = memo(() => {
	const { windowWidth } = useContext(WindowWidthContext);

	const getColumnsCount = useMemo(() => {
		if (windowWidth < 500) {
			return 2;
		} else if (windowWidth >= 500 && windowWidth < 900) {
			return 3;
		} else if (windowWidth >= 900 && windowWidth < 1240) {
			return 4;
		} else if (windowWidth >= 1240 && windowWidth < 1540) {
			return 5;
		} else if (windowWidth >= 1540 && windowWidth < 1920) {
			return 6;
		} else if (windowWidth >= 1920) {
			return 7;
		} else {
			return 8;
		}
	}, [windowWidth]);

	return (
		<Box
			className="image-and-title-card-loader"
			sx={{
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				paddingInline: { xs: "20px", sm: "30px", md: "60px" },
				display: "flex",
				width: {
					Webkit: "-webkit-fill-available",
					Moz: "-moz-available",
					width: "fill-available",
				},
			}}
		>
			<ImageList
				sx={{
					width: "100%",
					overflow: "hidden",
					gap: `2.5rem ${
						windowWidth <= 600 ? "10px" : "20px"
					} !important`,
					minHeight: "300px",
				}}
				cols={getColumnsCount}
			>
				{Array.from({ length: getColumnsCount * 2 }, (_, index) => (
					<Stack key={index} direction="column">
						<Skeleton
							key={index}
							variant="rectangular"
							animation="wave"
							height={300}
							sx={{
								"&::after": {
									background: `linear-gradient( 31deg, transparent, rgba(255, 255, 255, 0.22), transparent )`,
								},
							}}
						/>
						<Skeleton
							key={index}
							variant="text"
							animation="wave"
							height={70}
							sx={{
								"&::after": {
									background: `linear-gradient( 31deg, transparent, rgba(255, 255, 255, 0.22), transparent )`,
								},
							}}
						/>
					</Stack>
				))}
			</ImageList>
		</Box>
	);
});
