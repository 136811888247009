import { Alert, Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { CommentsSection } from "../CommentsSection/CommentsSection";
import { EpisodesList } from "../EpisodesList/EpisodesList";
import { InfoWrapperWatchPage } from "./InfoWrapperWatchPage";
import { VideoAndTitleCard } from "./VideoAndTitleCard";

export const WatchContainerMobile = memo(() => {
	const { id, episodeId } = useParams();
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));

	if (!id || !episodeId) {
		return <Alert severity="error">Wrong URL it seems!!</Alert>;
	}

	return (
		<Box
			className="watch-page-container-mobile"
			sx={{
				display: { xs: "flex", md: "none" },
				marginTop: { xs: "56px", sm: "64px" },
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				paddingInline: { xs: "20px", sm: "30px", md: "60px" },
			}}
		>
			<Stack direction="column" gap={3} sx={{ width: "100%" }}>
				<VideoAndTitleCard episodeId={episodeId} name={id} />
				<InfoWrapperWatchPage name={id} />
				<EpisodesList
					animeName={id}
					activeEpisode={episodeId}
					paddingX="0px"
					paddingY="0px"
					disableEpisodeSearch
					navigateToActiveEpisode
				/>
				<CommentsSection
					uniqueId={`${id} - ${episodeId}`}
					title={id}
					padding="0px"
					render={!isBigScreen}
				/>
			</Stack>
		</Box>
	);
});
