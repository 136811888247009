import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { Box, Button, Divider } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import ChipLayer from "./ChipLayer";
import { DetailCardProps } from "./DetailCard";
import { InfoWrapperMobile } from "./InfoWrapper";

const DetailCardMobile = memo(({ animeDetails }: DetailCardProps) => {
	const navigate = useNavigate();

	const onWatchClick = () => {
		navigate(`/watch/${animeDetails.Name}/1`);
	};

	return (
		<Box
			className="detail-page-mobile"
			sx={{
				display: { xs: "block", md: "none" },
				position: "relative",
				minWidth: "100%",
				overflow: "hidden",
				"& .detail-page-cover-img": {
					width: "auto",
					height: "50vi",
					maxHeight: "420px",
					objectFit: "cover",
					objectPosition: "center top",
					display: "block",
					zIndex: 2,
					position: "relative",
					pointerEvents: "none",
				},
			}}
		>
			<Box sx={{ minHeight: "600px" }}>
				<Box
					sx={{
						position: "absolute",
						top: "0",
						left: "0",
						right: "0",
						backgroundSize: "cover",
						backgroundPosition: "center center",
						filter: "blur(25px) grayscale(1)",
						opacity: "0.5",
						transform: "scale(1.2)",
						backgroundRepeat: "repeat-y",
						backgroundImage: `url(${animeDetails.Image})`,
						height: "inherit",
						maxHeight: "900px",
						minHeight: "600px",
						pointerEvents: "none",
					}}
				/>
				<Box
					className="detail-page-mobile-header"
					sx={{
						width: "100%",
						marginTop: "100px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						zIndex: 2,
					}}
				>
					<img
						className="detail-page-cover-img"
						src={animeDetails.Image}
						alt="detailPageCoverImage"
					></img>
					<Box
						sx={{
							marginTop: "30px",
							marginInline: {
								xs: "20px",
								sm: "30px",
								md: "60px",
							},
							lineHeight: "2.25rem",
							fontSize: "1.75rem",
							fontWeight: "600",
							textRendering: "optimizeLegibility",
							textAlign: "center",
							zIndex: 2,
						}}
					>
						{animeDetails.Name}
					</Box>
					<ChipLayer animeDetails={animeDetails} />
					<Button
						onClick={onWatchClick}
						sx={{
							backgroundColor: "white",
							border: "none",
							borderRadius: "16px",
							color: "black",
							fontSize: ".875rem",
							fontWeight: 900,
							marginTop: "30px",
							paddingRight: "17px",
							zIndex: 2,
							"&:hover": {
								color: "white",
							},
						}}
						disabled={
							!animeDetails.TotalEpisodes &&
							animeDetails.TotalEpisodes === 0
						}
					>
						<PlayArrowOutlinedIcon
							fontSize="large"
							sx={{
								color: "inherit",
							}}
						/>
						Watch now
					</Button>
				</Box>
				<InfoWrapperMobile animeDetails={animeDetails} />
			</Box>
			<Divider sx={{ borderColor: "white", marginTop: "10px" }} />
		</Box>
	);
});

export default DetailCardMobile;
