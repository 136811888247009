import { memo } from "react";
import { WatchContainerDesktop } from "../components/WatchContainer/WatchContainerDesktop";
import { WatchContainerMobile } from "../components/WatchContainer/WatchContainerMobile";
import { useSetPageTitle } from "../hooks/useSetPageTitle";

export const WatchPage = memo(() => {
	useSetPageTitle();
	return (
		<>
			<WatchContainerDesktop />
			<WatchContainerMobile />
		</>
	);
});
