import { Box } from "@mui/material";
import Disqus from "disqus-react";
import { memo } from "react";
import { useLocation } from "react-router-dom";

interface CommentsSectionProps {
	uniqueId: string;
	title: string;
	padding?: string;
	render?: boolean;
}

export const CommentsSection = memo(
	({ uniqueId, title, padding, render }: CommentsSectionProps) => {
		const location = useLocation();
		const disqusShortname = "anyani-me";
		const disqusConfig = {
			url: `http://localhost:3000${location.pathname}`,
			identifier: uniqueId,
			title,
		};

		return render ? (
			<Box
				sx={{
					margin: "20px 0",
					width: {
						Webkit: "-webkit-fill-available",
						Moz: "-moz-available",
						width: "fill-available",
					},
					paddingInline: padding ?? {
						xs: "20px",
						sm: "30px",
						md: "60px",
					},
				}}
			>
				<Disqus.DiscussionEmbed
					shortname={disqusShortname}
					config={disqusConfig}
				/>
			</Box>
		) : null;
	}
);
