import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const NotFoundPage = memo(() => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleGoBack = () => {
		if (location.key !== "default") {
			navigate(-1);
		} else {
			navigate("/");
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100%",
				height: "calc(100vh - 135px)",
			}}
		>
			<Box
				component="img"
				src="https://coverimagecdn.s3.us-west-2.amazonaws.com/NotFoundSaitama.png"
				alt="Not Found"
				sx={{
					width: "50vw",
					aspectRatio: "1 / 1",
					objectFit: "contain",
				}}
			/>
			<Button
				onClick={handleGoBack}
				aria-label="Go Back"
				sx={{
					backgroundColor: "white",
					borderRadius: "16px",
					color: "black",
					fontSize: ".875rem",
					fontWeight: 900,
					marginTop: "30px",
					paddingRight: "17px",
					paddingInline: "8px",
					zIndex: 2,
					"&:hover": {
						color: "white",
					},
				}}
			>
				Go Back
			</Button>
		</Box>
	);
});
