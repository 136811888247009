import SearchIcon from "@mui/icons-material/Search";
import { Alert, Box, InputAdornment, TextField } from "@mui/material";
import { Fragment, memo, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDescribeEpisodes } from "../../hooks/useDescribeEpisodes";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { EpisodeNumberPill } from "./EpisodeNumberPill";

interface EpisodesListProps {
	animeName: string;
	paddingX?: string;
	paddingY?: string;
	disableEpisodeSearch?: boolean;
	navigateToActiveEpisode?: boolean;
	activeEpisode?: string;
}

export const EpisodesList = memo(
	({
		animeName,
		disableEpisodeSearch = false,
		paddingY,
		paddingX,
		activeEpisode,
		navigateToActiveEpisode,
	}: EpisodesListProps) => {
		const {
			data: episodeList,
			isError,
			isLoading,
		} = useDescribeEpisodes({ query: animeName ?? "" });

		const [searchText, setSearchText] = useState("");
		const [filteredEpisodeList, setFilteredEpisodeList] = useState(
			episodeList?.data ?? []
		);
		const navigate = useNavigate();

		const filterEpisodesHandler = (value: string) => {
			if (!/^\d*$/.test(value)) return;
			setSearchText(value);
			const newFilteredList =
				episodeList?.data.filter((epNumber) =>
					epNumber.startsWith(value)
				) ?? [];
			if (newFilteredList.length) {
				setFilteredEpisodeList(newFilteredList);
			}
		};

		useEffect(() => {
			if (episodeList?.data) {
				setFilteredEpisodeList(episodeList.data);
			}
		}, [episodeList]);

		const handleOnClick = (ep: string) => {
			navigate(
				`/watch/${encodeURIComponent(animeName)}/${encodeURIComponent(
					ep
				)}`
			);
		};

		const scrollToButton = (buttonId: string) => {
			const button = document.getElementById(buttonId);
			if (button) {
				button.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
				});
			}
		};

		useLayoutEffect(() => {
			if (navigateToActiveEpisode && episodeList) {
				setTimeout(
					() => scrollToButton(`episode-pill-${activeEpisode}`),
					500
				);
			}
		}, [activeEpisode, episodeList, navigateToActiveEpisode]);

		let content;
		if (isLoading) {
			content = <LoadingSpinner />;
		}
		if (isError) {
			content = <Alert severity="error">Something went wrong!!</Alert>;
		}
		if (episodeList) {
			content = (
				<Fragment>
					<Box
						sx={{
							display: "inline-flex",
							justifyContent: "space-between",
						}}
					>
						<Box sx={{ float: "left", fontWeight: 700 }}>
							Episodes
						</Box>
						{!disableEpisodeSearch && (
							<TextField
								id="search-episode-textfield"
								placeholder="Search"
								maxRows={1}
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment
											position="start"
											sx={{
												color: "white",
											}}
										>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								sx={{
									background: "transparent",
									borderRadius: 3,
									width: { xs: "150px", sm: "230px" },
									display: "inline-flex",
									justifyContent: "space-evenly",
									"& .MuiInputBase-input": {
										paddingTop: "4px",
										paddingBottom: "4px",
										color: "rgb(204, 204, 204)",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "rgb(204, 204, 204)",
									},
								}}
								onChange={(event) =>
									filterEpisodesHandler(event.target.value)
								}
								value={searchText}
							/>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: "10px",
							marginY: "10px",
						}}
					>
						{filteredEpisodeList.map((ep) => (
							<EpisodeNumberPill
								key={ep}
								epNumber={ep}
								isSearched={ep === searchText}
								onClick={handleOnClick}
								isActive={ep === activeEpisode}
							/>
						))}
					</Box>
				</Fragment>
			);
		}

		return (
			<Box
				className="detail-page-episodes-list"
				sx={{
					paddingInline: paddingX ?? {
						xs: "20px",
						sm: "30px",
						md: "60px",
					},
					paddingY: paddingY ?? "10px",
					display: "flex",
					flexDirection: "column",
					position: "relative",
					zIndex: 2,
					boxSizing: "border-box",
					fontSize: "18px",
					lineHeight: "36px",
					fontWeight: 600,
					maxHeight: "500px",
					width: "100%",
					overflowY: "auto",
					overflowX: "hidden",
					background: "#141414",
					"&::-webkit-scrollbar": {
						width: "12px",
					},
					"&::-webkit-scrollbar-track": {
						backgroundColor: "#141414",
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: "#141414",
						borderRadius: "12px",
					},
					"&::-webkit-scrollbar-thumb:hover": {
						backgroundColor: "#a0a0a0",
					},
					"&": {
						scrollbarWidth: "thin",
						scrollbarColor: "#a0a0a0 #141414",
					},
				}}
			>
				{content}
			</Box>
		);
	}
);
