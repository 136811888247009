import { memo } from "react";
import { SuggestionCarousel } from "../components/SuggestionCarousel/SuggestionCarousel";
import { SuggestionContainer } from "../components/SuggestionContainer/SuggestionContainer";
import { SuggestionSwiperProvider } from "../Context/SuggestionSwiper.context";
import { useSetPageTitle } from "../hooks/useSetPageTitle";

const Home = memo(() => {
	useSetPageTitle();

	return (
		<>
			<SuggestionCarousel />
			<SuggestionSwiperProvider>
				<SuggestionContainer />
			</SuggestionSwiperProvider>
		</>
	);
});

export default Home;
