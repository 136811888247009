import { createContext, useEffect, useState } from "react";

export interface WindowWidthContextType {
	windowWidth: number;
}

interface WindowWidthProviderProps {
	children: React.ReactNode;
}

export const WindowWidthContext = createContext<WindowWidthContextType>({
	windowWidth: Number.POSITIVE_INFINITY,
});

export const WindowWidthProvider = ({ children }: WindowWidthProviderProps) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<WindowWidthContext.Provider value={{ windowWidth }}>
			{children}
		</WindowWidthContext.Provider>
	);
};
