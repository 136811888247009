import { Box, Chip, Link, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { DetailCardProps } from "./DetailCard";

export const ValueWithLabel = memo(
	({ label, value }: { label: string; value: string }) => (
		<Box
			className="detail-page-mobile-info-row"
			sx={{ display: "inline-flex" }}
		>
			<Typography
				sx={{
					fontWeight: "800",
					fontSize: "13px",
					lineHeight: "1.5rem",
					textSizeAdjust: "none",
					marginRight: "5px",
				}}
				color="white"
			>
				{label}:
			</Typography>
			<Typography
				sx={{
					fontWeight: "300",
					fontSize: "13px",
					lineHeight: "1.5rem",
					textSizeAdjust: "none",
				}}
				color="white"
			>
				{value}
			</Typography>
		</Box>
	)
);

export const DetailsValueWithLabelsMobile = memo(
	({ animeDetails }: DetailCardProps) => {
		const {
			Plot_Summary,
			Released,
			TotalEpisodes,
			WatchCount,
			Type,
			Genre,
		} = animeDetails;
		const navigate = useNavigate();

		return (
			<Stack
				direction="column"
				spacing={2}
				sx={{
					display: "flex",
					flexDirection: "column",
					position: "relative",
				}}
			>
				<Typography
					sx={{
						fontWeight: "400",
						fontSize: "13px",
						lineHeight: "1.4em",
						textSizeAdjust: "none",
						userSelect: "none",
					}}
					color="white"
				>
					{Plot_Summary?.trim() || ""}
				</Typography>
				<ValueWithLabel
					value={Released?.toString() || "0000"}
					label={"Released"}
				/>
				<ValueWithLabel
					value={TotalEpisodes?.toString() || "0"}
					label={"Episodes"}
				/>
				{Type && <ValueWithLabel value={Type.trim()} label={"Type"} />}
				<ValueWithLabel
					value={WatchCount?.toString() || "0"}
					label={"Views"}
				/>
				<Box
					sx={{
						display: "inline-flex",
						flexWrap: "wrap",
						marginTop: "10px",
					}}
				>
					<Typography
						sx={{
							fontWeight: "800",
							fontSize: "13px",
							lineHeight: "1.5rem",
							textSizeAdjust: "none",
							marginRight: "5px",
						}}
						color="white"
					>
						Genre:
					</Typography>
					{Genre &&
						Genre.map((genre) => (
							<Link
								key={genre}
								onClick={() => navigate(`/genre/${genre}`)}
							>
								<Chip
									sx={{
										color: "white",
										fontSize: "12px",
										lineHeight: "1.1em",
										height: "24px",
										marginRight: "5px",
										marginBottom: "5px",
									}}
									label={genre}
									key={genre}
									variant="outlined"
								/>
							</Link>
						))}
				</Box>
				<ValueWithLabel
					value={animeDetails.Other_Names?.join(", ") || ""}
					label={"Synonyms"}
				/>
			</Stack>
		);
	}
);
