import { Box } from "@mui/material";
import { Fragment, memo } from "react";

export const LoadingSpinner = memo(() => (
	<Fragment>
		<Box
			sx={{
				height: "300px",
				borderRadius: "50%",
				boxSizing: "border-box",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img
				src="https://coverimagecdn.s3.us-west-2.amazonaws.com/pedro_loading.gif"
				alt="Loading..."
				style={{
					width: "60px",
					height: "60px",
					borderRadius: "50%",
				}}
			/>
		</Box>
	</Fragment>
));
