import { Alert, Box, ImageList } from "@mui/material";
import { Fragment, memo, useContext } from "react";
import { WindowWidthContext } from "../../Context/WindowWidth.context";
import { useSearchAnime } from "../../hooks/useSearchAnime";
import { SearchAnimeResult } from "../../services/anyanime.service.models";
import { ImageAndTitleCard } from "../ImageAndTitleCard/ImageAndTitleCard";
import { ImageAndTitleCardSkeleton } from "../LoadingSpinner/ImageAndTitleCardSkeleton";

interface MoreLikeThisProps {
	baseAnime: string;
	columnCount: number;
	padding?: string;
}

export const MoreLikeThis = memo(
	({ baseAnime, padding, columnCount }: MoreLikeThisProps) => {
		const { windowWidth } = useContext(WindowWidthContext);
		const { data, isError, isLoading, isFetching } = useSearchAnime({
			query: baseAnime.includes("(Dub)")
				? baseAnime.replace("(Dub)", "").slice(0, 80)
				: `${baseAnime.slice(0, 80)} (Dub)`,
		});

		let content;

		if (isLoading || isFetching) {
			content = <ImageAndTitleCardSkeleton />;
		}
		if (isError) {
			content = <Alert severity="error">Something went wrong!!</Alert>;
		}

		if (data?.pages) {
			const searchResults = data.pages
				.reduce(
					(prev, curr) => [...prev, ...(curr.data ?? [])],
					[] as SearchAnimeResult[]
				)
				.filter((el) => el.Name !== baseAnime);
			content = (
				<Fragment>
					<ImageList
						className="search-results-list"
						sx={{
							overflow: "hidden",
							gap: `2.5rem ${
								windowWidth <= 600 ? "10px" : "20px"
							} !important`,
							minHeight: "300px",
							marginTop: "10px",
							paddingInline: padding ?? {
								xs: "20px",
								sm: "30px",
								md: "60px",
							},
						}}
						cols={columnCount}
					>
						{searchResults
							.slice(
								0,
								searchResults.length -
									(searchResults.length % columnCount)
							)
							.map((detail) => (
								<ImageAndTitleCard
									key={detail.Name}
									imgUrl={detail.Image}
									title={detail.Name}
									totalEpisodes={detail.TotalEpisodes}
								/>
							))}
					</ImageList>
				</Fragment>
			);
		}

		return (
			<Box
				className="more-like-this-container"
				sx={{
					marginTop: { xs: "20px", sm: "30px" },
					flexDirection: "column",
					display: "flex",
					width: "100%",
				}}
			>
				<Box
					className="suggestion-swiper-heading"
					sx={{
						fontSize: "1.375rem",
						lineHeight: "1.75rem",
						verticalAlign: "bottom",
						fontWeight: 700,
						marginBottom: "min(2vi, 20px)",
						textRendering: "optimizeLegibility",
						paddingInline: padding ?? {
							xs: "20px",
							sm: "30px",
							md: "60px",
						},
					}}
				>
					Related searches
				</Box>
				{content}
			</Box>
		);
	}
);
