import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

export const useSetPageTitle = () => {
	const location = useLocation();
	const params = useParams();

	useEffect(() => {
		const titleMap: { [key: string]: string | ((params: any) => string) } =
			{
				"/": "AnyAnime: Watch Popular Anime on the fly",
				search: "Search - AnyAnime",
				anime: (params) => `Watch ${params.id} - AnyAnime`,
				watch: (params) =>
					`Ep ${params.episodeId} - ${params.id} : Watching on AnyAnime`,
				type: (params) => `List "${params.id}" at AnyAnime`,
				genre: (params) => `Genre "${params.id}" at AnyAnime`,
				list: "AnimeList - AnyAnime",
			};

		// Match the current route with a key in titleMap
		const matchedRoute = Object.keys(titleMap).find(
			(route) =>
				location.pathname === "/" ||
				location.pathname.split("/")[1] === route
		);

		// Generate the title
		let newTitle: string;
		if (matchedRoute && typeof titleMap[matchedRoute] === "function") {
			newTitle = (titleMap[matchedRoute] as (params: any) => string)(
				params
			);
		} else if (matchedRoute && typeof titleMap[matchedRoute] === "string") {
			newTitle = titleMap[matchedRoute] as string;
		} else {
			newTitle = "Feeling lost - AnyAnime";
		}

		document.title = newTitle;
	}, [location, params]);
};
