import { Alert, Box, Grid, List, ListItem, ListItemText } from "@mui/material";
import debounce from "lodash.debounce";
import { Fragment, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAnimeList } from "../../hooks/useGetAnimeList";
import { AnimeDetail } from "../../model/anime-detail";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

export const AnimeListContainer = memo(() => {
	const navigate = useNavigate();
	const {
		data: animeListResults,
		isError,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
	} = useGetAnimeList({});

	useEffect(() => {
		const fetchMoreData = async () => {
			if (!(isLoading || isFetching) && hasNextPage) {
				await fetchNextPage();
			}
		};
		const handleScroll = debounce(() => {
			const { scrollTop, clientHeight, scrollHeight } =
				document.documentElement;
			const isMobile = window.innerWidth <= 768;
			const scrollOffset = isMobile ? 1000 : 750;
			if (
				scrollTop + clientHeight >= scrollHeight - scrollOffset &&
				!(isLoading || isFetching)
			) {
				fetchMoreData();
			}
		}, 10);

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [fetchNextPage, hasNextPage, isFetching, isLoading]);

	const handleClick = (name: string) => {
		navigate(`/anime/${encodeURIComponent(name)}`);
	};
	let content;

	if ((isLoading || isFetching) && !animeListResults) {
		content = <LoadingSpinner />;
	}
	if (isError) {
		content = <Alert severity="error">Something went wrong!!</Alert>;
	}

	if (animeListResults) {
		const results = animeListResults.pages.reduce(
			(prev, curr) => [...prev, ...(curr.data ?? [])],
			[] as Pick<AnimeDetail, "Name">[]
		);
		const [splitArray1, splitArray2] = [
			results.filter((_, i) => i % 2 === 0),
			results.filter((_, i) => i % 2 !== 0),
		];

		content = (
			<Fragment>
				<List dense sx={{ width: "100%" }}>
					{results.map((_, i) => (
						<Grid
							key={i}
							container
							spacing={0}
							sx={{
								width: "100%",
							}}
						>
							{splitArray1[i] ? (
								<Grid
									item
									xs={12}
									lg={6}
									sx={{
										paddingLeft: "0px",
									}}
								>
									<ListItem sx={{ padding: "0px" }}>
										<ListItemText
											primary={splitArray1[i].Name}
											onClick={() =>
												handleClick(splitArray1[i].Name)
											}
										/>
									</ListItem>
								</Grid>
							) : null}
							{splitArray2[i] ? (
								<Grid
									item
									xs={12}
									lg={6}
									sx={{ paddingLeft: "0px" }}
								>
									<ListItem sx={{ padding: "0px" }}>
										<ListItemText
											primary={splitArray2[i].Name}
											onClick={() =>
												handleClick(splitArray2[i].Name)
											}
										/>
									</ListItem>
								</Grid>
							) : null}
						</Grid>
					))}
				</List>
				{hasNextPage && <LoadingSpinner />}
			</Fragment>
		);
	}
	return (
		<Box
			className="genre-container"
			sx={{
				marginTop: { xs: "56px", sm: "64px" },
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				paddingInline: { xs: "20px", sm: "30px", md: "60px" },
				display: "flex",
			}}
		>
			<Box
				sx={{
					WebkitBackgroundClip: "text !important",
					backgroundClip: "text !important",
					WebkitTextFillColor: "transparent",
					color: "transparent",
					display: "inline-block",
					position: "relative",
					width: "100%",
					fontSize: "1.5rem",
					marginTop: "24px",
					background:
						"linear-gradient(74deg, #4285f4 0, #9b72cb 9%, #d96570 20%, #d96570 24%, #9b72cb 35%, #4285f4 44%, #9b72cb 50%, #d96570 56%, #4285f4 75%, #9b72cb 100%)",
				}}
			>
				AnimeList
			</Box>
			{content}
		</Box>
	);
});
